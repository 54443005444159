import importApi from '@/api/core/importer'
import Vue from 'vue';

const initialState = () => ({
    entity: null,
    sessionRecords: null,  // On  guardem els registres de sessions d'importacio inacavades
    records: [],
    percentage: 0,
    importableFields: {},
    outputFieldSelection: {},
    fieldMapping: {},
    headingOnFirstRow: false,
    importStatus: [],
    recordsAdded: 0,
    recordsUpdated: 0,
    recordsError: 0,
    errors: [],
    possibleValues: {},
    editing: { columnIndex: null, recordIndex: null }    // Guarda informacio de quins registres (records) s'estan editant
});

export const importer = {
    state: initialState(),
    mutations: {
        resetCenterInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        setSessionRecords(state, sessionRecords) {
            state.sessionRecords = sessionRecords;
        },
        setRecords(state, records) {
            state.records = records;
            let i = 0;
            // Vue.set(state.editing[i], j, false);
            records.forEach((e, index) => Vue.set(state.importStatus, index, null));
        },
        setImportableFields(state, value) {
            state.importableFields = value;
        },
        setRecordsAdded(state, value) {
            state.recordsAdded = value;
        },
        setRecordsUpdated(state, value) {
            state.recordsUpdated = value;
        },
        setRecordsError(state, value) {
            state.recordsError = value;
        },
        setPercentage(state, value) {
            state.percentage = value;
        },
        setPossibleValues(state, value) {
            state.possibleValues[value.field] = value.values;
        },
        setErrors(state, value) {
            // Estableix els errors que hi han en un registre
            Vue.set(state.errors, value.index, value.errors);
        },
        setOutputFieldSelection(state, value) {
            //console.log("setOutputFieldSelection store", value);
            state.outputFieldSelection = value
        },
        setEntity(state, entity) {
            state.entity = entity;
        },
        removeRecord(state, index) {
            let i = index;
            state.records.splice(index, 1);
            state.errors.splice(index, 1);
            let toRemove = state.importStatus.slice(index, 1);
            state.importStatus.splice(index, 1);
            this.dispatch('reduceCounters', toRemove[0]);

        },
        setImportStatus(state, data) {
            Vue.set(state.importStatus, data.index, data.status);
        },
        setFieldMapping(state, data) {
            if (data === null) {
                data = {}
            }
            state.fieldMapping = data;
        },
        setHeadingOnFirstRow(state, data) {
            state.headingOnFirstRow = data;
        },
        setEditing(state, data) {
            state.editing.recordIndex = data.recordIndex;
            state.editing.columnIndex = data.columnIndex;
        }
    },
    actions: {
        setEntity(context, params) {
            context.commit("setEntity", params.entity);

        },
        upload(context, params) {

            context.commit("setPercentage", 0);
            return importApi.extract(params.entity, params.file, context)
        },
        findActiveSession(context, params) {
            return importApi.findActiveSession(params.entity);
        },
        updateSession(context, onlyErrors) {
            // Deixem a la importSession nomes els registres que han quedat amb error
            let records;
            if (onlyErrors === undefined || onlyErrors) {
                records = context.state.records.filter((record, index) => context.state.importStatus[index] == 'error')
            } else {
                records = context.state.records;
            }
            importApi.updateSession(context.state.entity, records,
                context.state.headingOnFirstRow, context.state.fieldMapping);
        },
        async downloadFile(context, params) {
            let result = {};
            await importApi.downloadFile(params.entity, params.filename).then(response => {
                // console.log(response.headers[content]);
                result.data = response.data;
                //console.log("Headers:");
                //console.log(response.headers['content-type']);
                result.type = response.headers["content-type"];
            })
            return result;

        },
        async getSampleFiles(context, params) {
            let files = [];
            await importApi.getSampleFiles(params.entity).then(response => {
                response.data.forEach(f => files.push(f))
            })
            return files;
        },
        async importRecord(context, params) {
            let fm = {};
            let withLoading = false;

            Object.entries(context.state.outputFieldSelection).forEach(function (e) {
                //console.log("e", e);
                if (e[1] != null) {
                    let index = e[0];
                    let property = e[1];

                    if (typeof context.state.fieldMapping[index] !== "undefined" && context.state.fieldMapping[index] != null) {
                        fm[property.value] = parseInt(context.state.fieldMapping[index].value);
                    } else {
                        fm[property.value] = null;
                    }
                }
            });
            let recordIndex = params.index;
            // if (context.state.headingOnFirstRow) recordIndex++;

            let records = [];
            let status = [];
            if (params.simulation === true) {
                records = context.state.records.slice(recordIndex, recordIndex + params.blockSize);
                status = context.state.importStatus.slice(recordIndex, recordIndex + params.blockSize);
                status.forEach(state => {
                    context.dispatch('reduceCounters', state);
                });
            } else {
                for (let i = recordIndex; i < recordIndex + params.blockSize; i++) {
                    if (context.state.importStatus[i] !== 'error') {
                        records.push(context.state.records[i])
                    }
                    else{
                        records.push([]);
                        context.commit('setRecordsError', context.state.recordsError + 1);
                    }
                }
            }

            if (records.length === 0) {
                return;
            }

            withLoading = 'withLoading' in params && params.withLoading === true;
            if (withLoading) {
                context.commit('setLoading', true);
            }
            //console.log("before importRecord ", context.state.entity, records, fm, params.simulation);
            await importApi.importRecord(context.state.entity, records, fm, params.simulation).then(response => {
                //console.log("after importRecord ", response);
                let index = 0;
                records.forEach((record, i) => {
                    if(record && record.length !== 0){
                        let nextState = response.data[index].nextStatus;
                        //console.log("nextState", response.data[index]);
                        let errors = response.data[index].errors;
                        context.commit('setImportStatus', {"index": recordIndex + i, "status": nextState});
                        if (nextState === 'success') {
                            context.commit('setRecordsAdded', context.state.recordsAdded + 1);
                            context.commit('setErrors', {index: recordIndex + index, errors: []})
                        }
                        if (nextState === 'update') {
                            context.commit('setRecordsUpdated', context.state.recordsUpdated + 1);
                            context.commit('setErrors', {index: recordIndex + index, errors: []})
                        }
                        if (nextState === 'error' || nextState === 'warning') {
                            //console.log("entra a nexState error o Warning, content error? ", errors)
                            context.commit('setRecordsError', context.state.recordsError + 1);
                            context.commit('setErrors', {index: recordIndex + index, errors: errors});
                            // Mirem si en els errors, hi ha un camp "values" dins el errorField
                            // Values indica la llista de possibles valors que pot pendre el camp
                            errors.forEach(e => {
                                if (typeof (e.values) !== 'undefined') {
                                    if (typeof (context.state.possibleValues[e.errorField]) == 'undefined') {
                                        context.commit("setPossibleValues", {field: e.errorField, values: e.values});
                                    }
                                }
                            })
                        }
                        index++;
                    }
                });
            }).catch(error => {
                let errorMessage = "";

                if (error?.response) {
                    errorMessage = error.response.data.error;
                } else if (error?.message) {
                    errorMessage = error.message;
                }

                //console.log('error >>>>>>>', response);
                // son errors 500 per tan efecten a tot el block
                records.forEach((record, i) => {
                    let error = {errorField:"", invalidValue: "", message: errorMessage,  parameters: []};

                    if (record && record.length !== 0) {
                        context.commit('setImportStatus', {"index": recordIndex + i, "status": "error"});
                        //console.log('setRecordsError', context.state.recordsError + 1);
                        context.commit('setRecordsError', context.state.recordsError + 1);
                        //console.log('setErrors', {index: recordIndex + i, errors: [error]});
                        context.commit('setErrors', {index: recordIndex + i, errors: [ error ]});
                    }
                });
            }).finally(() => {
                if (withLoading) {
                    context.commit('setLoading', false);
                }
            });
        },
        exportErrors(context, params) {
            let failedRecords = context.state.records.filter((e, index) => context.state.importStatus[index] == 'error')

            importApi.exportErrors(context.state.entity, failedRecords, context.state.records[0]).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'text/csv'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.csv');
                document.body.appendChild(link);
                link.click();
            });
        },
        clearImportSubtotals(context, params) {
            context.state.recordsUpdated = 0;
            context.state.recordsAdded = 0;
            context.state.recordsError = 0;
        },
        clearErrors(context) {
            context.state.errors = [];
        },
        reduceCounters(context, state) {
            switch (state) {
                case 'success':
                    let counter = context.state.recordsAdded - 1;
                    counter = counter < 0 ? 0 : counter;
                    context.commit('setRecordsAdded', counter);
                    break;
                case 'update':
                    let counterUpdate = context.state.recordsUpdated - 1 < 0 ? 0 : context.state.recordsUpdated - 1;
                    context.commit('setRecordsUpdated', counterUpdate);
                    break;
                case 'error':
                    let counterError = context.state.recordsError - 1 < 0 ? 0 : context.state.recordsError - 1;
                    context.commit('setRecordsError', counterError);
            }
        },
        clearImportStatus(context) {
            let length = Object.entries(context.state.importStatus).length;
            for (let i = 0; i < length; i++) {
                context.commit('setImportStatus', {"index": i, "status": null});
            }
        }
    },
    getters: {
        records: state => {
            return state.records
        },
        errorGroups: state => {
            let errorGroups = {};
            state.errors.forEach((errorList, index) => errorList.forEach(error => {
                if(error && error.message){
                    let key = error.errorField;
                    if (typeof errorGroups[key+'_'+error.message] == 'undefined') errorGroups[key+'_'+error.message] = { error: error, rows: [] };
                    errorGroups[key+'_'+error.message]['rows'].push(index);
                }
            }));

            return errorGroups;
        }
    }
}
