export default {
    "error.500": "Hay un error interno en el sistema",
    "error.404": "No se ha encontrado la página",
    "error.403": "No tienes los permisos necesarios para efectuar esta acción",
    "error.1001" : "La persona no tiene acceso a la aplicación. Crea un usuario si quieres ver y gestionar permisos",
    "error.1002" : "Permiso desconocido",
    "error.1003" : "Usuario no válido",
    "error.5001": "El código debe ser único",
    "error.5002": "La posición debe ser única",
    "error.other": "Ha habido un error",
    "error.differentArea": "El departamento no coincide",
    "error.subjectNameInUse": "El nombre de la materia está en uso",
    "error.subjectCodeInUse": "El código de la materia está en uso",
    "error.save": "No se han podido guardar los cambios",
    "error.teacherRoleNotExist": "El rol no existe",
    "error.invalidDniFormat": "El DNI no tiene un formato válido",
    "error.invalidNifFormat": "El NIF no tiene un formato válido",
    "error.invalidZipFormat": "El código postal no tiene un formato válido",
    "error.contactFormat": "El contacto no tiene un formato válido",
    "error.contactPersonNotFound": "No se ha encontrado la persona a la cual se quiere asociar el contacto",
    "error.contactOwnedByOtherPerson": "El contacto es de {person}",
    "error.contactDuplicated": "Contacto duplicado",
    "error.personWithIdNotFound": "No se ha encontrado a la persona con el ID especificado",
    "error.dniUsedByAnotherPerson": "El DNI pertenece a otra persona. {person}",
    "error.nifUsedByAnotherPerson": "El NIF pertenece a otra persona. {person}",
    "error.niaUsedByAnotherPerson": "El NIA pertenece a otra persona. {person}",
    "error.groupCourseStageNotExist": "El grupo no existe",
    "error.person.personRole.min": "La persona debe pertenecer como mínimo a un grupo del sistema",
    "invalid_grant": "Combinación usuario clave de paso incorrectos",
    "error.userPassword": "Combinación usuario clave de paso incorrectos",
    "error.passwordMissMatch": "Las contraseñas deben coincidir",
    "error.fieldMandatory": " El campo {field} es obligatorio",
    "error.startDateBiggerThanEndDate": "Fecha inicio mayor que fecha fin",
    "error.codeExists" : "Código ya utilizado",
    "error.nameExists": "Nombre ya utilizado",
    "error.correct": "Por favor, corrija los errores",
    "error.user.add.pwd.required": "Es necesario indicar la contraseña de acceso a la aplicación para poder crear el usuario",
    "error.wrongFormat": "Formato incorrecto",
    "error.fieldCanNotBeEmpty": "El campo {field} no puede estar vacío",
    "error.groupCourseStageNotEmpty": " El {group} no está vacío. Debe eliminar los alumnos y/o las programaciones asociadas",
    "error.noDelete": "No se ha podido eliminar el contenido",
    "error.code_coursestage_idExists": "El código ya existe en este curso",
    "error.fieldIsMandatory": "El campo es obligatorio",
    "error.contacts.update": " al guardar la información de los contactos",
    "error.parents.update": " al guardar la información de los padres/tutores u otras personas relacionadas con el alumno",
    "error.parents.deleted": " al eliminar alguno de los padres del alumno",
    "error.contacts.deleted": " al eliminar el contacto",
    "error.Notenoughfields": "No se han encontrado los campos mínimos requeridos",
    "error.emptyfile": "No hay ningún registro",
    "error.reviewWrongFields": "Revise los campos marcados con error",
    "error.notValidExtension": "Extensión no válida",
    "error.center_id_nameExists": "Ya existe un elemento con este nombre",
    "error.center_id_codeExists": "Ya existe un elemento con este código",
    "error.name_stage_idExists": "Nombre en uso",
    "error.code_stage_idExists": "Código en uso",
    "error.tagNotExists": "El tag no existe",
    "error.categoryNotExists": "La categoría no existe",
    "error.noTeachers": "iEduca no dispone de profesores",
    "error.coursestage_id_subject_idExists": "La programación ya está definida. Cambia la materia y/o el tipo",
    "error.tagGroupNotExists": "La categoría no existe",
    "error.delete": "No ha sido posible eliminar el elemento seleccionado",
    "error.MustChooseOneCenter": " Debe seleccionar un Centro",
    "error.entityNotFound": "No se ha encontrado ningún elemento con el ID indicado",
    "error.CourseStageHaveGroups": " El nivel contiene cursos. Se deben quitar primero",
    "error.templateCanNotBeDisabled": "La plantilla no se puede desactivar, ya que es la única activa para esta funcionalidad",
    "error.canNotDeleteTemplateBecauseOnlyActive": "No se puede eliminar la plantilla, porque es la única activa para esta funcionalidad",
    "error.templateNotExist": "La plantilla no existe",
    "error.personDniNotExist": "No existe ninguna persona con este DNI",
    "error.personIdNotExist": "No existe ninguna persona con este ID",
    "error.usernameUsedByAnotherPerson": "El nombre de usuario pertenece a otra persona",
    "error.passwordTooShort": "La contraseña es demasiado corta",
    "error.personIdentifierNotSpecified": "No se ha especificado ningún identificador para la persona",
    "error.idIsNotAnInteger": "El ID debe ser un número",
    "error.noSelected": "No ha seleccionado ningún elemento",
    "error.WrongCode": " Código erróneo",
    "error.contactAlreadyAssignedToThisPerson": "La persona ya tiene este contacto",
    "error.contactOwnsOtherPerson": "El contacto pertenece a otra persona",
    "error.PersonNoPermissionsOrAccess": "La persona no tiene permisos o usuario para acceder",
    "error.codeNotValid": "Código no válido",
    "error.nameNotValid": "Nombre no válido",
    "error.nameNotValidLength": "La longitud del nombre supera el límite de caracteres permitido",
    "error.notSaved": "No ha sido posible guardar los cambios",
    "error.notSelected": "No ha seleccionado ninguna opción",
    "error.RoleNotExists": "El rol no existe",
    "error.body":"No se ha podido guardar",
    "error.enrolmentNumInUse": "El número de matrícula ya existe",
    "error.firstMarkType": "Para añadir observaciones, primero necesita seleccionar un tipo de falta",
    "error.load": "No ha sido posible cargar la información",
    "error.yearInUse": "Este año se está utilizando en más de un centro, por lo tanto, solo se puede activar o desactivar, para editar la resta de campos, vaya a la vista global",
    "error.IdIsNotNumber": "El campo debe ser numérico",
    "error.removeGroup" : "No se ha podido eliminar el grupo",
    "error.enrolmentsExists": "No es posible eliminar esta programación porque actualmente existen alumnado matriculado. Elimina previamente las matrículas vinculadas",
    "error.templateNameAlreadyExistsInGlobal": "Ya hay una plantilla con el mismo nombre en la gestión global",
    "error.NumberOfAffectationIsBiggerThan1": "No se puede pasar a local si afecta a más de un centro",
    "error.NumberOfAffectationIsEqualTo0": "No se puede pasar a local porque no afecta a ningún centro",
    "error.templateNameAlreadyExistsInCenter": "Ya hay una plantilla con el mismo nombre en este centro",
    "error.programNotExists": "La programación no existe",
    "error.nameSurname1InUse": "Ya existe una persona con el mismo nombre y apellido",
    "error.evaluationPeriodHasEvaluables": "El período de evaluación no se puede eliminar porque tiene evaluables asociados",
    "error.FinalEvaluableForEvaluationAndGroupAlreadyExists": "Ya existe un evaluable final para este grupo y periodo de evaluación",
    "error.fileNotSaved": "No se ha podido guardar el fichero",
    "error.canNotCreateFolder": "No ha sido posible crear la carpeta",
    "error.loadUserFolder": "No ha sido posible cargar el directorio personal",
    "error.routeNotValid": "La ruta especificada no es válida",
    "error.folderExists": "La carpeta ya existe",
    "error.fileNotFound": "El fichero no existe",
    "error.OnlyOneEvaluableWithConvocatoryForProgramAllowed": "Esta programación ya tiene un evaluable para esta convocatoria",
    "error.personMustHaveOneSystemGroup": "La persona debe por lo menos un rol del sistema",
    "error.key_nameExists": "El nombre está en uso",
    "error.CanNotRemoveGlobalEntity": "No es posible eliminar el elemento porque tiene dependencias",
    "error.NoPermissionToCreateEnrolment": "No tienes permisos para matricular alumnos",
    "error.operationNotAllowed": "Operación no válida",
    "error.name_stage_id_center_idExists": "Este nombre ya existe",
    "error.SubjectDoesntExist": "La materia no existe",
    "error.GroupDoesntExist": "El grupo no existe",
    "error.PersonDoesntExist": "La persona no existe",
    "error.kcIdNotValid": "K&C ID no válido",
    "error.templateCanNotBeSaved": "La plantilla no se puede guardar, ya que existen colisiones entre distintas etiquetas de diferentes grupos",
    "error.canNotDeleteSystemTemplate": "Las plantillas de sistema no pueden ser eliminadas",
    "error.convocatoryHasEvaluables": "La convocatoria tiene evaluables asociados",
    "error.fieldContainsNotAllowedCharacters": "El campo contiene caracteres especiales no permitidos. (Permitidos: letras, números, puntos, guiones y guiones bajos)",
    "error.sendDay.future": "Esta fecha de envío no es válida",
    "error.sendHour.future": "Esta hora de envío no es válida",
    "error.program_id_competence_idExists": "La dimensión ya está añadida a esta programación",
    "error.nameMandatory": "El nombre es obligatorio",
    "error.enrolmentsNotExists": "No hay alumnos matriculados",
    "error.groupIsNotEmpty": "No se puede borrar el grupo porque tiene miembros asociados",
    "error.notExistCourseStage": "El nivel/curso no existen",
    "error.emptyText": "El texto está vacío",
    "error.notExistClassContent": "El contenido no existe",
    "error.evaluationNotExists": "La evaluación no existe",
    "error.CompetenceHasGrades": "No se puede borrar una de las competencias porque tiene notas en esta programación",
    "error.AmbitCannotBeEmpty": "Es obligatorio que una competencia pertenezca a un ámbito",
    "error.CantDeleteNotOwnedCompetences": "No se puede borrar esta competencia porque pertenece a otra persona",
    "error.DimensionMustBeEmptyToDelete": "Para borrar la dimensión, primero debe borrar todas sus competencias",
    "error.studentNotEnrolled": "El alumno no está matriculado",
    "error.textCannotBeEmpty": "El texto no puede estar vacío",
    "error.code_center_idExists": "Ya existe un elemento con este código",
    "error.name_center_idExists": "Ya existe un elemento con este nombre",
    "error.noTemplateDefinedForThisCourse": "No hay ninguna plantilla definida para este curso",
    "error.coursestage_id_functionality_idExists": "Ya existe una plantilla definida de esta funcionalidad para este curso",
    "error.enrolment_id_program_idExists": "Ya existe una matrícula para este alumno, en el mismo grupo y con las mismas materias",
    "error.PreinscriptionEmailInUse": "Ya existe un usuario con el correo electrónico indicado en la plataforma",
    "error.PreinscriptionEmailAlreadyRegistered": "Ya existe un usuario con el correo electrónico indicado en esta preinscripción",
    "error.PreinscriptionPhoneInUse": "Ya existe un usuario con el teléfono indicado en la plataforma",
    "error.PreinscriptionPhoneAlreadyRegistered": "Ya existe un usuario con el teléfono indicado en esta preinscripción",
    "error.FamiliarMandatory": "Si el alumno es menor de edad, es obligatorio añadir un tutor",
    "error.preRegistrationNotOpen": "La preinscripción está cerrada",
    "error.nameOrKeyNameInUse": "El nombre ya existe",
    "error.hour_ini_hour_endExists": "La franja horaria ya existe",
    "error.AmbitMustBeEmptyToDelete": "Para borrar el ámbito debe de estar vacío",
    "Stage no esta buit": "No se puede eliminar este nivel porque contiene cursos. Hay que borrarlos primero",
    "error.CourseStageNotEmpty": "No se puede eliminar el curso {curso} porque contiene grupos, hay que borrarlos primero",
    "error.cannotSaveProgramContent": "No se ha podido guardar la planificación",
    "programContentDeleted": "Se ha borrado la planificación",
    "error.name_group_idExists": "Ya existe un elemento con este nombre para este grupo",
    "error.gradeValueNotValid": "El valor introducido no es válido para este tipo de evaluable",
    "error.classeCompetenceNotExists": "La competencia no está vinculada en esta sesión y no se puede eliminar",
    "error.classeCompetenceIdNotValid": "El ID de la competencia de esta sesión no es válido",
    "error.competenceIdNotValid": "El ID de la competencia no es válido",
    "error.competenceNotExists": "No existe la competencia",
    "error.classeNotValid": "Esta sesión no es válida",
    "error.repetitionEventNotDragable": "Este evento no puede ser arrastrado por tratarse de un evento de repetición, por favor indica que quiere hacer a continuación",
    "error.PhoneIsMandatory": "El teléfono es obligatorio",
    "error.MailIsMandatory": "El correo electrónico es obligatorio",
    "error.programCodeNotExist": "No existe ninguna programación con este/os código/s: ",
    "error.noTemplatesAvailable": "No hay ninguna plantilla disponible para imprimir",
    "error.enrolmentWithSamePropertiesExists": "El alumno ya está matriculado en este grupo",
    "error.classeNotInRequest": "Clase no válida",
    "error.classeNotExists": "La clase no existe",
    "error.removeProgramEnrolmentExists": "La programación tiene matrículas asociadas",
    "error.removeProgramClasseExists": "La programación tiene clases asociadas",
    "error.removeProgramCompetenceExists": "La programación tiene competencias asociadas",
    "error.removeProgramContentExists": "La programación tiene contenido asociado",
    "error.subjectCannotBeNull": "La materia no puede estar vacía",
    "error.studentIsInClass": "El alumno ya está en esta clase",
    "error.classe_id_session_id_competence_idExists": "Este criterio ya está asociado a la sesión actual",
    "error.commerceGlobalStageLocal": "No es posible guardar los cambios porque el Comercio ya existe al centro local",
    "error.commerceLocalStageGlobal": "No es posible guardar los cambios porque el comercio depende de un registro local",
    "error.commerceStageDifferentCenter": "No es posible guardar los cambios porque los centros no coinciden",
    "error.cantActivateIfAvailabilityHasNoGroup": "No se puede activar esta preinscripción porque tiene disponibilidades sin ningún grupo asignado",
    "error.fileExists": "Este fichero ya existe",
    "error.subject_id_coursestage_idExists": "La programación ya existe para este curso",
    "error.startDateFormatNotValid": "El formato de la fecha inicial es inválido",
    "error.programContentNotExists": "No existe este contenido",
    "error.classeContentNotExistsOrDeleted": "Este contenido ha sido borrado o no existe",
    "error.cannotDeleteProgramContent": "No se puede borrar el contenido",
    "error.programContentNotInRequest": "Contenido no válido",
    "error.functionalityNotDefined": "Este curso no tiene ninguna funcionalidad definida para poder ejecutar esta acción",
    "error.timetableWithoutChanges": "No se han detectado cambios en el horario",
    "error.frequencyCannotBeNull": "El campo frecuencia no puede ser vacío",
    "error.evaluableHasGrades": "El evaluable tiene notas",
    "error.evaluableNoPermission": "No tienes permiso para modificar este evaluable",
    "error.classe_id_session_id_document_idExists": "Ya está inserido este documento en esta sesión",
    "error.competenceLinkedToClasses": "La competencia puede estar enlazada a una clase",
    "error.gradeNotExists": "La nota no existe",
    "error.ProgramIdCannotBeNull": "El identificador de la materia no puede estar vacío",
    "error.GroupIdCannotBeNull": "El identificador del grupo no puede estar vacío",
    "error.studentCanNotViewGradesOfOtherStudent": "El estudiante no puede ver las notas de otro estudiante",
    "error.parentCanNotViewGradesOfOtherStudent": "No pueden ver las notasde otro estudiante que no sea familiar",
    "error.stageCoordinatorCanNotViewGradesOfOtherStudent": "No se pueden ver las notas de un estudiante que no pertenece a un nivel del qual eres coordinador",
    "error.studentIsNotFromGroupOfEvaluable": "El estudiante no está en el grupo del evaluable",
    "error.teacherIsNotInProgram": "El profesor no imparte esta materia en esta clase",
    "error.canNotViewGradesOfThisGroup": "No tienes permiso para visualizar las notas de este grupo",
    "error.CantEditNotOwnedCompetences": "No se pueden modificar las competencias que no son propias",
    "error.CompetenceDoesntExist": "La competencia no existe",
    "error.AmbitDoesntExist": "El ámbito no existe",
    "error.ProgramIdCantBeNull": "El identificador de la materia no puede estar vacío",
    "error.ProgramDoesntExist": "La materia no existe",
    "error.evaluationPeriodIdNotValid": "El identificador del periodo no es válido",
    "error.evaluableIdsNotValid": "Los identificadores de los evaluables no son válidos",
    "error.studentCantBeNull": "El estudiante no puede estar vacío",
    "error.subjectCantBeNull": "La materia no puede estar vacía",
    "error.studentIdCannotBeNull": "El identificador del estudiante no puede estar vacío",
    "error.studentNotFound": "No se ha encontrado el estudiante",
    "error.courseStageIdCannotBeNull": "El identificador del curso no puede estar vacío",
    "error.courseStageNotFound": "No se ha encontrado el curso",
    "error.evaluationPeriodIdCannotBeNull": "El identificador del periodo no puede estar vacío",
    "error.evaluationPeriodNotFound": "No se ha encontrado el periodo",
    "error.groupNotFound": "No se ha encontrado el grupo",
    "error.programNotFound": "No se ha encontrado la materia",
    "error.FunctionalityNoFound": "No se ha encontrado la funcionalidad",
    "error.personNotExists": "La persona no existe",
    "error.personIdNotValid": "El identificador de la persona no es válido",
    "error.programIdNotValid": "El identificador de la materia no es válido",
    "error.evalCompetEvalPerNotExists": "El evaluable, la competencia o el periodo de evaluación no existen",
    "error.evalIdCompetIdEvalPerIdNotValid": "Los identificadores del evaluable, de la competencia o del periodo de evaluación no son válidos",
    "error.valueNotValid": "El valor no es válido",
    "error.commentGradeNotExists": "El comentario de la nota no existe, ya se ha borrado.",
    "error.commentGradeIdNotValid": "El identificador del comentario de la nota no es válido",
    "validate.contact.format": "Valida el formato del contacto",
    "error.valueExists": "El valor ya existe",
    "error.enrolment.not_exist": "No existe esta matrícula",
    "error.groupRelatedNotFound": "No se ha encontrado el grupo",
    "error.groupIdNotValid": "El identificador de grupo no es válido",
    "error.studentsIdsNotValid": "El identificador del alumno no es válido",
    "error.noCommentsSaved": "No se ha podido guardar el comentario",
    "error.noAllCommentsSaved": "No se han podido guardar los comentarios",
    "error.name_group_id_program_idExists": "El nombre ya existe para este grupo.",
    "error.subjectNotExists": "La materia no existe.",
    "error.courseStageOrSubjectNotValid": "Nivel, Curso y Materia son obligatorios para la categoría seleccionada.",
    "error.reportNotDefined": "Este curso no tiene un informe predefinido.",
    "error.subjectHaveProgram": "La materia tiene programaciones vinculadas.",
    "error.subjectHaveChildren": "La materia tiene hijos.",
    "error.commentIsObligatory": "Es obligatorio poner un comentario.",
    "error.PermissionDenied": "No tiene permiso.",
    "error.children.update": "Error en actualizar los hijos de este usuario.",
    "error.groupNotExists": "No existe el grupo.",
    "error.subjectIdNotValid": "El id de la materia no es válido.",
    "error.courseStageNotExists": "No existe el curso.",
    "error.courseStageIdNotValid": "El id del curso no es válido.",
    "error.sessionNotExists": "No existe la sesión.",
    "error.fileNameNotValid": "El nombre del fichero no es válido.",
    "error.documentNotFound": "No se ha encontrado el documento.",
    "error.tokappNotFound": "No se ha encontrado ningún usuario de Tokapp con este contacto.",
    "error.person_id_evaluable_id_convocatory_idExists": "Es un valor duplicado.",
    "error.enrolmentAlreadyExists": "Ya existe una matricula de este alumno en este grupo.",
    "error.commentDefaultNotFound": "El comentario predefinido no existe.",
    "error.commentDefaultCanNotSave": "El comentario no puede ser modificado.",
    "error.commentDefaultCanNotDeleted": "El comentario no puede ser eliminado.",
    "error.groupAndCourseStageDontMatch": "El nivel, el curso y la materia no coinciden con los valores del grupo.",
    "error.canNotGenerateReportEmptyData": "No se puede generar el informe porque no hay datos.",
    "Access denied.": "Acceso denegado.",
    "error.competenceCommentGradeExists": "Hay algun comentario de una nota con esta competencia asociada",
    "error.competenceCompetenceEvaluableExists": "Hay algun evaluable con esta competencia asociada",
    "error.competenceProgramCompetenceExists": "Hay alguna programación con esta competencia asociada",
    "error.NoTemplateFoundForFunctionalityAndCourseStage": "No se ha encontrado ninguna plantilla para este curso.",
    "error.required.groupCourseStage": "Es necesario indicar el grupo",
    "error.attendanceTypeNotInStage": "El tipo de asistencia no está activado para este curso.",
    "error.CourseStageNotEmptyPrograms": "No se puede eliminar el curso {curs} porque contiene programaciones. Debe eliminarlas previamente.",
    "error.CourseStageNotEmptyConvocatories": "No se puede eliminar el curso {curs} porque contiene convocatorias. Debe eliminarlas previamente.",
    "error.GroupCourseStageNotFound": "No se ha encontrado el grupo.",
    "error.PersonNotFound": "No se ha encontrado a la persona.",
    "error.ConvocatoryNotFound": "No se ha encontrado la convocatoria.",
    "error.ConvocatoryCodeCanNotBeEmpty": "El código de la convocatoria no puede estar vacío.",
    "error.ConvocatoryNameCanNotBeEmpty": "El nombre de la convocatoria no puede estar vacío.",
    "error.CenterNotFound": "No se ha encontrado el centro.",
    "error.ParentConvocatoryNotFound": "No se ha encontrado la convocatoria padre.",
    "error.ConvocatoryCanNotBeItsOwnParent": "La convocatoria no puede ser su propio padre.",
    "error.ConvocatoryHasDependencies": "La convocatoria tiene dependencias.",
    "error.ConvocatoryHasParent": "La convocatoria tiene padres.",
    "error.ConvocatoriesIdsMustBeArray": "Se tiene que enviar un array de ids de convocatorias.",
    "error.OneDateCanNotBeEmpty": "La fecha no puede estar vacía.",
    "error.StartDateBeforeParentStartDate": "La fecha de inicio es anterior a la fecha de inicio del padre.",
    "error.EndDateAfterParentEndDate": "La fecha de fin es posterior a la fecha de fin del padre.",
    "error.CantChangeParentOfConvocatoryWithGrades": "No se puede modificar el padre de una convocatoria con cursos.",
    "error.ConvocatoryIdCanNotBeEmpty": "El id de la convocatoria no puede estar vacío.",
    "error.PositionIsNotAnInteger": "La posición no es un número.",
    "error.dniExists": "Este DNI ya existe.",
    "error.loadingPaymentMethods": "No ha sido posible recuperar los métodos de pago.",
    "error.yearNotSelected": "Falta seleccionar el año.",
    "error.PersonFieldNotEmpty": "No se puede modificar el tipo porque hay personas con datos de este campo.",
    "error.personIsNotEnrolledToEvaluableProgram": "La persona no está matriculada a la materia.",
    "error.hoursActivity": "Debes introducir un número entero a la cantidad de horas.",
    "error.bankAccountExistsOnSamePerson": "El número de cuenta ya se encuentra registrado",
    "error.PersonIsTeacherInTimetableDraftRepetition": "La persona está asignada como profesor en los siguientes eventos del horario (la versión del horario aún no está activa):",
    "error.differentProgramClassAndGroup": "La programación de la clase no coincide con la programación asociada al grupo.",
    "error.createdCompanyButNotSynced": "La empresa se ha guardado correctamente pero no ha sido posible sincronizar con KeyAndCloud.",
    "error.updatedCompanyButNotSynced": "La empresa se ha actualizado correctamente pero no ha sido posible sincronizar con KeyAndCloud.",
    "error.contactTypeValueMismatch": "El valor del contacto no es del tipo indicado",
    "error.emailNotExists": "El correo no corresponde a ninguno de nuestros usuarios en el sistema.",
    "error.CopyDataToSchemaError": "Error al copiar los datos del año.",
    "error.yearNameExists": "Ya existe este nombre, pruebe con otro diferente.",
    "error.centerYearExists": "Ya existe este curso.",
    "error.GroupCourseStageCourseStageIdNotMatchProgramCourseStageId": "Se está intentando añadir una planificación a un grupo que tiene un curso diferente.",
    "error.billingDoesNotAllowToViewGradesForParent": "La visualización de calificaciones esta bloqueada por que actualmente tienes una duda vencida de ${payment}, comunícate con la institución.",
    "error.stageTypeNotExists": "El nivel no existe.",
    "error.ConvocatoryHasSiblingRecovery": "Ya hay una convocatoria de recuperación al mismo nivel. Solo puede existir una convocatoria de recuperación por nivel",
    "error.ConvocatoryHasNoParent": "La convocatoria no tiene ningún nivel superior. Es necesario que la convocatoria tenga un nivel superior para establecerla como convocatoria de recuperación.",
    "error.ConvocatoryHasMoreThanOneParent": "La convocatoria tiene más de un nivel superior. Es necesario que la convocatoria tenga un único nivel superior para establecerla como convocatoria de recuperación.",
    "error.canNotDeleteTemplateBecauseOnlyOneActive": "No se puede eliminar la plantilla porque es la única activa para esta funcionalidad.",
    "error.ErrorActivatingTimetable": "Se ha producido un error al activar el horario. Por favor, revisa los siguientes eventos:",
    "error.GroupAndStudentCannotBeNull": "Falta el grupo o el alumno.",
    "error.SubjectCannotBeNull": "Falta la materia.",
    "error.TeachersCannotBeNull": "Falta el profesor.",
    "error.CourseStageCannotBeNull": "Falta el curso.",
    "error.StageCannotBeNull": "Falta el nivel.",
    "error.valueTooLong": "Error: se han seleccionado demasiados elementos o el valor introducido es demasiado grande",
    "error.billingDoesNotAllowToViewGradesForStudent": "La visualización de calificaciones está bloqueada por cartera vencida.",
    "error.personHaveEnrollment": "El usuario tiene matrículas activas.",
    "error.soonHaveEnrollment": "El usuario es familiar de un alumno con matrículas activas.",
    "error.minOneCenterRole": "El usuario debe tener como mínimo un permiso asignado a un centro.",
    "error.surname1Invalid": "El formato del apellido no es válido",
    "error.roomHasClasses": "El aula está asociada a una o varias clases",
    "error.roomHasTimetableActiveEvents": "El aula está asociada a uno o varios horarios",
    "error.ParameterNotAllowedToChange": "No se puede cambiar este parámetro",
    "error.cannotSaveClasseContent": "No se ha podido guardar",
    "error.EntityIdNotExists": "La entidad no existe",
    "error.documentLinkedToClasses": "No ha sido posible eliminar el elemento. Hay uno o más documentos vinculados a Clases.",
    "error.ErrorActivatingTimetableCA": "Se ha producido un error al activar el horario:",
    "error.StageNotExists": "El nivel no existe",
    "error.CourseStageNotExists": "El curso no existe",
    "error.SubjectNotExists": "La materia no existe",
    "error.CompetenceNotExists": "La competencia no existe",
    "error.EvaluabletypeValueNotExists": "El tipo de nota no existe",
    "error.ValueRequired": "El valor es obligatorio",
    "error.actionNotAllowed": "Acción no permitida.",
    "error.someRecipientsAreNotAllowed": "No tiene permiso para comunicarse con uno o más contactos del mensaje.",
    "error.quantityMustBeGreaterThan0": "Debe ser mayor que 0",
    "error.quantityMustBeNumber": "Debe ser un número",
    "error.notGlobalStage": "Es necesario pasar el Nivel a Global, antes que el curso.",
    "error.importGenericError": "Error al importar",
    "error.name_coursestage_idExists": "Ya existe un curso con el mismo nombre.",
    "error.nameStageNotValid": "Nombre de nivel incorrecto.",
    "error.nameCourseStageNotValid": "Nombre de curso incorrecto.",
    "error.enrolmentNumNotValid": "Número de matricula incorrecto.",
    "error.courseStageNotDeletable": "No se puede eliminar el curso porqué tiene las siguientes dependencias:",
    "error.automationNotActive": "El automatismo de publicación de horarios no está activo.",
    "error.timetableActivationExists": "Ya existe un proceso de publicación pendiente o activo.",
    "error.canNotDeleteActivation": "No ha sido posible cancelar la publicación del horario. Es posible que el procéso de publicación ya se esté ejecutando.",
    "error.personNotSynced": "Persona no l'ha pogut vincular amb Key And Cloud",
    "error.some403": "No tienes permisos para ver algunos recursos de esta página",
    "error.sendGradesReportWithoutContacts": "Revisar contactos",
    "error.nameSurname1Surname2InUse": "Ya existe una persona con el mismo nombre y apellidos.",
    "error.activationDateNotValid": "Fecha de activación no válida.",
    "error.deactivationDateNotValid": "Fecha desactivación no válida.",
    "error.endDateBeforeStartDate": "Fecha desactivación debe ser superior a Fecha de activación.",
    "error.groupIdOravailabilityIdNotValid": "Grupo o disponibilidad no válidos.",
    "error.groupCourseStageNotValid": "El Grupo seleccionado no es válido.",
    "error.niaExists": "Ya existe una persona con el mismo identificador externo.",
    "error.personExistsChild": "El alumno ya existe",
    "error.personExistsFather": "El familiar 1 ya existe",
    "error.personExistsMother": "El familiar 2 ya existe",
}
