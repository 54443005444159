import axios from "axios";
export default {
    getToken() {
        //console.log('API getToken');
        // return new Promise((resolve, reject) => {
        //     //console.log('Generate token')
        //     // Simulació d'una crida asíncrona
        //     setTimeout(() => {
        //         resolve({token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxI…Dk0fQ.aUYH21yTr-tYdhOdT9bJnA5-4fyqhtx6LFH-2eSGZg4'});
        //     }, 1000);
        // });
        return axios.get('/centrifugo/token').then((response) => {
            if (response) {
                //console.log('response', response.data)
                return response.data
            }
        }).catch((error) => {
            console.log("ERROR en el getToken --> "+error)
        });
    },
}
