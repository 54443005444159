<template>
    <transition
            name="modal"
            @after-enter="$emit('open')"
            @after-leave="$emit('close')">
        <div :id="identifier" class="ieduca-dialog modal-mask" :class="visibility">
            <div class="modal-wrapper">
                <div :id="'modalContainer'+'_'+this.identifier" 
                    :class="['modal-container', 'shadow', decoration, {'draggable' : draggable, 'with-icon': withIcon}]"
                    @mousedown="dragStart($event)"
                    @mouseup="dragEnd($event)"
                    @mouseout="dragEnd($event)"
                    @mousemove.prevent="drag($event)">
                    <div :class="{'icons-modal': true, 'justify-content-end': !goBack}">
                        <a v-if="goBack" href="#" class="btn-modal-back" @click.stop="fncGoBack">
                            <i class="icon icon-arrow-left"></i>
                        </a>
                        <div class="d-inline-flex">
                            <div v-if="draggable && (deviceSize == 'md' || deviceSize == 'lg' || deviceSize == 'xl')" :class="{'wrapper-icon': true, 'icon-clicked': draggableActive}" href="#" @click.stop="changeStatusDragabble">
                                <i class="icon icon-arrow-move"></i>
                            </div>
                            <div class="wrapper-icon btn-modal-close" href="#" @click.stop="close()">
                                <i class="icon icon-close-alt2"></i>
                            </div>
                        </div>
                    </div>
                    <div class="spinner-ie" v-if="loading">
                        <div class="spinner-ie-content">
                            <spinner :status="true" :color="getThemeColor()" :size="24" :depth="4" :speed="2"/>
                            <span class="vertical-center-inside">{{ $t('loading') }}</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <div :class="iconClass" class="big-icon col" v-if="modalIcon != ''">
                                <i :class="modalIcon"></i>
                            </div>
                            <div :class="[customCss(contentClass), 'content-modal']">
                                <span class="d-inline-flex mb-2 vertical-super">
                                    <h3 class="title" v-if="modalTitle" v-html="modalTitle"></h3>
                                    <a href="#" class="pl-2 wrapper-icon vertical-center-inside" v-if="showTitleBtn" @click.stop="fncTitleBtn" :title="titleBtn">
                                        <i :class="'icon ' + iconTitleBtn"></i>
                                    </a>
                                </span>
                                <p v-if="modalSubtitle">{{modalSubtitle}}</p>
                                <div :id="'content_'+this.identifier">
                                    <slot name="content"></slot>
                                    <slot name="contentNoClass"></slot>
                                </div>
                            </div>
                        </div>
                        <slot name="extraContent"></slot>
                        <div :class="['modal-footer', {'bg-blur': loading, 'footerColor': footerColor}]" v-if="$slots.footer">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import $ from 'jquery';
    import Spinner from 'vue-spinner-component/src/Spinner.vue';
    import ElementQueries from 'css-element-queries/src/ElementQueries';
    import ResizeSensor from 'css-element-queries/src/ResizeSensor';
    import { mapState } from 'vuex';

    export default {
        name: "ModalIe",
        components: {
            Spinner,
        },
        props: {
            id: {type: String, default: ""},
            title: {type: String, default: ""},
            decoration: {type: String, default: "default"},
            width: {default: 50},
            modalIcon: {type: [String, Object], default: ""},
            timeToLive: {type: Number, default: 0},
            visible: {type: [Number, Boolean], default: true},
            loading: {type: [Number, Boolean], default: false},
            goBack: {type: Boolean, default: false},
            contentClass: {type: String, default: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"},
            iconClass: {type: String, default: ""},
            scrollbar: {type: Boolean, default: true},
            showTitleBtn: {type: Boolean, default: false},
            iconTitleBtn: {type: String, default: "icon-plus-alt2"}, //Només la classe de la icona, ex: icon-id
            titleBtn: {type: String, default: null},
            draggable: {type: Boolean, default: false, required: false},
            footerColor: {type: Boolean, default: false, required: false},
            subtitle: {type: String, default: null},
            maxWidth: {default: 100}, //Porcentajes 1-100, o en valor exacto (10px, 40rem, etc.)
        },
        data() {
            return {
                observer: null,
                modalTitle: this.title,
                modalSubtitle: this.subtitle,
                draggableActive: false,
                isDragging: false,
                currentX: 0,
                currentY: 0,
                initialX: 0,
                initialY: 0,
                xOffset: 0,
                yOffset: 0,
            }
        },
        watch: {
            width(newValue) {
                this.setSize();
            },
            maxWidth(newValue) {
                this.setSize();
            },
            title(newValue) {
                this.modalTitle = newValue;
            },
            subtitle(newValue) {
                this.modalSubtitle = newValue;
            }
        },
        methods: {
            show() {
                if ($('#' + this.identifier + '.ieduca-dialog').hasClass('d-none')) {
                    $('#' + this.identifier + '.ieduca-dialog').removeClass('d-none');
                }
            },
            destroy() {
                $('#' + this.identifier + '.ieduca-dialog').remove();
            },
            open() {
                // Alias de show.
                this.show();
            },
            close() {
                if (!$('#' + this.identifier + '.ieduca-dialog').hasClass('d-none')) {
                    $('#' + this.identifier + '.ieduca-dialog').addClass('d-none');
                }
                this.$emit('close')
            },
            setSize() {
                if(this.width === 'auto'){
                    $('#' + this.identifier + ' .modal-container').css("width", "auto");
                }
                else{
                    $('#' + this.identifier + ' .modal-container').css("width", this.width + "%");
                }
                if(typeof this.maxWidth === 'string'){
                    $('#' + this.identifier + ' .modal-container').css("max-width", this.maxWidth);
                }
                else{
                    $('#' + this.identifier + ' .modal-container').css("max-width", this.maxWidth + "%");
                }
                $('#' + this.identifier + ' .modal-container').css("max-width", this.maxWidth + "%");
                $("#content_"+this.identifier).css("height", "auto");
                let modalContainer = $("#modalContainer_"+this.identifier).height() / $('#modalContainer_'+this.identifier).parent().height() * 100;
                if (this.scrollbar) {
                    if (modalContainer <= 75 && $("#modalContainer_"+this.identifier).height() > 0) {
                        $("#modalContainer_"+this.identifier).css("overflow-y", "visible");
                        $("#content_"+this.identifier).css("height", "auto");
                    } else {
                        // Afecta al vue-multiple.
                        // $("#modalContainer_"+this.identifier).css("overflow-y", "scroll");
                        $("#content_"+this.identifier).css("overflow-y", "scroll");
                        $("#content_"+this.identifier).css("height", $("#modalContainer_"+this.identifier).height()-150 + "px");
                    }
                }
                // $("#modalContainer_"+this.identifier).css("height", this.height + "px");
                $("#modalContainer_"+this.identifier).css("height", "auto");
            },
            customCss(css){
                var defaultIcon = "col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10";
                var defaultCss = "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12";

                if(this.modalIcon && css == defaultCss){
                    return defaultIcon;
                } else {
                    return css;
                }
            },
            fncTitleBtn(){
                this.$emit('fncTitleBtn');
            },
            fncGoBack(){
                this.$emit('goBack');
                $("#content_"+this.identifier).css("height", "auto");
            },
            dragStart(e) {
                if(this.draggable && this.draggableActive){
                    this.initialX = e.clientX - this.xOffset;
                    this.initialY = e.clientY - this.yOffset;
                    this.xOffset = this.currentX;
                    this.yOffset = this.currentY;
                    $('.modal-container').css("transform", "translate3d(" + this.currentX + "px, " + this.currentY + "px, 0)");
                    this.isDragging = true;
                }
            },
            dragEnd(e) {
                this.isDragging = false;
            },
            drag(e) {
                if (this.isDragging && this.draggable) {
                    e.preventDefault();
                    this.currentX = e.clientX - this.initialX;
                    this.currentY = e.clientY - this.initialY;

                    this.xOffset = this.currentX;
                    this.yOffset = this.currentY;

                    $('.modal-container').css("transform", "translate3d(" + this.currentX + "px, " + this.currentY + "px, 0)");
                }
            },
            changeStatusDragabble(){
                if(this.draggable){
                    this.draggableActive = !this.draggableActive;
                    if(this.draggableActive){
                        $('.modal-container').css("cursor","move");
                    } else {
                        $('.modal-container').css("cursor","default");
                    }
                }
            }
        },
        computed: {
            ...mapState({
                deviceSize: state => state.deviceSize,
            }),
            visibility() {
                let isVisible = "d-none";

                if (this.lodash.isFinite(this.visible) && this.visible > 0) {
                    setTimeout(() => {
                        $('#' + this.identifier + '.ieduca-dialog').addClass('d-none');
                        // console.log("add display none", $('#' + this.identifier + '.ieduca-dialog'));
                    }, this.visible * 1000);

                    isVisible = "";
                } else if (this.visible === true) {
                    isVisible = "";
                }

                return isVisible;
            },
            error: {
                get() {
                    return this.$store.state.error;
                }
            },
            withIcon() {
                return !this.lodash.isEmpty(this.modalIcon);
            },
            identifier() {
                if (this.id != "") {
                    return this.id;
                } else {
                    return this.lodash.uniqueId('ieduca_modal_');
                }
            }
        },
        mounted() {
            this.setSize();
            let self = this;
            new ResizeSensor($('#modalContainer_'+this.identifier), function (a) {
                self.setSize();
            });
            $(window).resize(function (){
                self.setSize();
            });
        },
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/variables.scss";

    .content-modal {
        color: $black;
        background-color: white;
    }

    .big-icon {
        text-align: center;
        padding: 0rem 0rem 1rem 1rem !important;

        i {font-size: 4rem;}
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0000004d;
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        overflow-y: auto;
        vertical-align: middle;
    }

    .modal-container {
        margin: 0px auto;
        background-color: #fff;
        transition: all .3s ease;
        font-family: $font, sans-serif;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        width: 50%;
        max-height: calc(100vh - 20vh);
        min-height: 120px;
        min-width: 550px;
        border-top: 9px solid $brand1;
        scrollbar-width: thin;

        .btn-modal-close {
            font-size: 1rem;
            color: #6f768b;
            z-index: 999;
        }

        .btn-modal-back {
            font-size: 0.75rem;
            color: #6f768b;
            z-index: 999;
        }

        .icons-modal{
            display: flex;
            justify-content: space-between;
            padding: 5px 15px 5px 20px;
        }

        &.draggable{
            transform: inherit;
            position: absolute;
            top: 20px;
            margin: auto;
        }
    
        &.with-icon {
            .modal-header, .modal-body {padding-left: 120px !important;}
        }

        &.small {
            min-width: 375px !important;
            width: 375px !important;
            font-size: 1rem;
            @media (max-width: 320px){
                min-width: 90% !important;
                width: 90% !important;
            }
        }

        &.medium {
            min-width: 564px !important;
            width: 564px !important;
            font-size: 1rem;
        }

        &.large {
            min-width: 800px !important;
            width: 800px !important;
            font-size: 1rem;
        }

        &.success {
            border-top: 9px solid $success !important;
            .big-icon {color: $success !important;}
        }

        &.error {
            border-top: 4px solid $error !important;
            .big-icon {color: $error !important;}
        }

        &.warning {
            border-top: 9px solid $warning !important;
            .big-icon {color: $warning !important;}
        }

        &.info {
            border-top: 9px solid $info !important;
            .big-icon {color: $info !important;}
        }

        &.notification {
            .modal-header {
                padding: 44px 48px 12px 48px;
                h3 {font-weight: 700;}
            }
        }
    }



    .modal-header {
        border: none;
        padding: 48px 48px 47px 48px;

        h3 {
            margin: 0;
            padding: 0;
            text-align: left;
            width: 100%;
            font-size: 20px;
        }
    }

    .modal-body {
        padding: 0 48px 48px 48px;
        color: $semiNegre;
    }

    .modal-footer {
        border-top: none !important;
        &.footerColor{
            background-color: $backColor !important;
            margin-top: 0.5em;
        }
    }

    .modal-default-button {
        float: right;
    }

    /* TRANSITION */
    .modal-enter, .modal-leave-active {
        opacity: 0;
        
        .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    .bg-blur {
        -webkit-filter: opacity(15%);
        filter: opacity(15%);

    }

    .spinner-ie {
        margin-top: 1rem;
        margin-bottom: 3rem;
        text-align: center;

        .spinner-ie-content {
            display: inline-flex;
            align-items: center;

            .sl-spinner {
                float: left;
            }

            span {
                font-size: 1.5rem;
                padding-left: 0.5rem;
                padding-left: 0.5rem;
                color: black;
            }
        }
    }

    @media (max-width: 576px) {
        .modal-container {
            max-width: 90% !important;

            &.medium {
                min-width: 90% !important;
                width: 90% !important;
                padding-bottom: 40px;
            }

            &.large {
                min-width: 90% !important;
                width: 90% !important;
            }
        }
    }
</style>
<style lang="scss">
    // .content-modal > * ~ * > div div[class="row"], .content-modal >* + div[class*="row"], .content-modal > * + div > div[class*="row"]{ //content modal que contenga un row (no el siguiente hijo)
    //     background-color: greenyellow !important;
    //     margin-left: -15px !important;
    //     margin-right: -15px !important;
    // }
</style>
