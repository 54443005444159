import axios from 'axios';
import entityHelper from '@/api/entityHelper'
//al profile i la persona van a controllers diferents ja que els permisos són diferents
// no es el mateix gestionar-te a tu mateix (password, tags...) que gestionar a una persona

export default {
    findPersons(filters, page, pageSize, orderField, orderMode){
        return entityHelper.findEntity("/persons", filters, page, pageSize, orderField, orderMode);
    },
    findPersonObject(cancelToken, name,surname1,surname2){
        let parameters = {};
        if(name !== undefined && name !==''){
            parameters.name = name;
        }
        if(surname1 !== undefined && surname1 !==''){
            parameters.surname1 = surname1;
        }
        if(surname2 !== undefined && surname2 !==''){
            parameters.surname2 = surname2;
        }
        return axios.get('/persons/find',{
            params: parameters,
            cancelToken: cancelToken?.token
        });
    },
    findPersonByCompleteName(cancelToken, completeName){
        return axios.get('/persons/find',{
            params: {completeName: completeName},
            cancelToken: cancelToken?.token
        });
    },
    getPerson(personId, schemaName){
        //console.log("------> getPersona: ",personId, " - schemaName: ",schemaName);
        if(personId=="undefined" || personId==null){
            return axios.get('profile');
        }else{
            let url = '/person/'+personId;
            if(schemaName != null){
                url += "/"+schemaName;
            }
            let config = {
                'params': {
                    'schemaName': ((schemaName != null) ? schemaName : null)
                }
            };
            // return axios.get(url, config);
            return axios.get(url);
        }
    },
    centerImage(codeCenter, urlImg){
        return axios.get('/centerImage/'+codeCenter+'/'+urlImg,{responseType: 'arraybuffer'}).then(
            (response) => {
              var bytes = new Uint8Array(response.data); // get info de la imatge que hem serveix el backend
              var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), ''); // bytes latin
              return "data:image/jpeg;base64," + btoa(binary); // base62
            }
        );
    },
    getPossibleParents(personId, evenStudents) {
        let params = {evenStudents: evenStudents};
        //console.log("getPossibleParents");
        return axios.get('/person/possibleParents/'+personId, {params});
    },
    getPossibleChildren(personId, evenNotStudents) {
        //console.log("getPossibleParents");
        let params = {evenNotStudents: evenNotStudents};
        return axios.get('/person/possibleChildren/'+personId, {params});
    },
    /* saveParents(personId, parents) {
        console.log("---> saveParents - personId: "+personId+" - parents: "+parents);
        return entityHelper.saveEntity(parents, '/parent/'+personId);
    },*/
    saveParent(parent) {
        // console.log("---> saveChild",parent);
        return entityHelper.saveEntity(parent, '/parent');
    },
    saveChild(child) {
        // console.log("---> saveParent - parentId: "+parent.id+" - objParent: ",parent);
        return entityHelper.saveEntity(child, '/child');
    },
    deleteParent(personRelationId) {
        // console.log("---> deleteParent - personRelationId: "+personRelationId);
        return axios.delete('/parent/'+personRelationId);
    },
    deletePerson(personId, force = false) {
        // console.log("---> deletePerson: "+personId, force);
        let url = '/person/'+personId;
        if (force) {
            url += '?force=true';
        }
        return axios.delete(url);
    },
    savePerson(person) {
        // console.log("savePerson ---> person: ",person);
        return entityHelper.saveEntity(person, '/person');//'+person.id);
    },
    addTagToPerson(personId, tagName) {
        return axios.post('/tags/person/'+personId+'/'+tagName)
    },
    deleteTagToPerson(personId, tagName) {
        return axios.delete('/tags/person/'+personId+'/'+tagName)
    },
    getPersonTags(personId){
        return axios.get('/tags/person/'+personId)
    },
    getPersonEnrolment(personId,yearCode){
        return axios.get('/enrolment/person/'+personId+'/'+yearCode);
    },
    savePersonLanguage(personId, language){
        return axios.post('/person/'+personId+'/language/'+language);
    },
    getParents(id) {
        return axios.get('/parents/' + id);
    },
    getTeachersByStudent(id) {
        return axios.get('/student-teachers/' + id);
    },
    getChildren(id) {
        return axios.get('/children/' + id);
    },
    getTeachersByParent(id) {
        return axios.get('/parent-teachers/' + id);
    },
    getParentsByTeacher(id) {
        return axios.get('/teacher-parents/' + id);
    },
    getStudentsByTeacher(id) {
        return axios.get('/teacher-students/' + id);
    },
    getTeachers(cancelToken, course, stage, subject, returnAllIfEmptyResult, filterYear=null){
        let params = {course: course, stage: stage, subject: subject, returnAllIfEmptyResult: returnAllIfEmptyResult};
        if(filterYear && filterYear != undefined){
            params.year = filterYear;
        }
        return axios.get('/persons/teachers', {params: params, cancelToken: cancelToken.token});
    },
    getProfilePicture(personId){
        return axios.get('personPicture/'+personId,{responseType: 'arraybuffer'}).then(
            (response) => {
                if(response.data!="" && response.data!="undefined" && response.data.byteLength>0){
                    var bytes = new Uint8Array(response.data); // get info de la imatge que hem serveix el backend
                    var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), ''); // bytes latin
                    return "data:image/jpeg;base64," + btoa(binary); // base62
                }else {
                    return "";
                }
            }
        );
    },
    getPersonRoles(allRoles){
        if(!allRoles){
            allRoles= false;
        }
        let params = {allRoles: allRoles};
        return axios.get('/currentPersonRoles', {params: params});
    },
    switchUser(username){
        let params={'_switch_user': username,'force':false}
        return axios.get('menu',{params});
    },
    exitSwitchUser(){
        return axios.get('/exitSwitchUser');
    },
    saveNewParent(entity){ // s'utilitza aquesta funció ja que els alumnes i pares potencials tenen unes casuístiques diferents
        return entityHelper.saveEntity(entity,'/person/parent');
    },
    saveNewCreatedParent(childId, newParentData, relationTypeSelected, isAllowedViewInfo){
        let params = {
            childId: childId,
            newParentData: newParentData,
            relationTypeSelected: relationTypeSelected,
            isAllowedViewInfo: isAllowedViewInfo
        };
        return axios.post('/saveNewCreatedParent', {params});
    },
    saveNewCreatedChild(parentId, newChildData, relationTypeSelected, isAllowedViewInfo){
        let params = {
            parentId: parentId,
            newChildData: newChildData,
            relationTypeSelected: relationTypeSelected,
            isAllowedViewInfo: isAllowedViewInfo
        };
        return axios.post('/saveNewCreatedChild', {params});
    },
    savePersonProfilePicture(personId, picture){
        let params = {
            personId: personId,
            picture: picture
        };
        return axios.post('/savePersonProfilePicture', {params});
    },

    getUserSessions(userId){
        return axios.get('/activesession/'+userId);
    },

    deleteSessionUser(sessionId){
        return axios.delete('/activesession/'+sessionId);
    },
    deleteProfilePicture(personId){
        return axios.delete('/personPicture/'+personId);
    },
    getPersonsGlobalList(pageSize) {
        if (pageSize == -1) {
            return axios.get('/persons-list');
        } else {
            return axios.get('/persons-list', { params: { pageSize: pageSize } });
        }
    },
    sendUserReminder(usersIds, types, withoutAccess, neverSent, year) {
        let params = {
            'usersIds': [],
            'types': [],
            'withoutAccess': false,
            'neverSent': false,
            'year': ""
        };

        if (Array.isArray(usersIds) && usersIds.length > 0) {
            params['usersIds'] = usersIds;
        }

        if (Array.isArray(types) && types.length > 0) {
            params['types'] = types;
        }

        if (withoutAccess != null) {
            params['withoutAccess'] = withoutAccess;
        }

        if (neverSent != null) {
            params['neverSent'] = neverSent;
        }

        if (year != null && year != "") {
            params['year'] = year;
        }

        return axios.post("/users/send-reminder", params);
    },
    savePersons(persons){
        return axios.post('/persons', {persons});
    },
    getFields(filters) {
        let params = { filters: filters }
        // console.log("cridaapi: filters: ", filters);
        return axios.get('/person-field', {params});
    },
    getPersonFields(personId){
        return axios.get('/person-field', {params: { personId: personId } });
    },
    removeField(id){
        return axios.delete('person-field/'+id);
    },
    saveField(entity) {
        return entityHelper.saveEntity(entity,'/person-field');
    },
    getStudentsCourseStage(courseStageId){
        return axios.get('/persons-coursestage', {params: { courseStageId: courseStageId } });
    },
    setActive(personId, active){
        return axios.post('/person/'+personId+'/set_active', {params: { active: active } });
    },
    promote(personIds, groupCourseStageId, year = null){
        let params = {
            personIds: personIds,
            groupCourseStageId: groupCourseStageId,
            year: year
        }
        return axios.post('/enrolments/promote', params);
    },
    sendEnrolmentMail(personsId, year = null){
        let params = {
            personsId: personsId,
            year: year
        }
        //CAMBIAR LA URL POR LA CORRECTA
        return axios.post('/enrolments/sendPDFMail', params);
    },
    getPersonsNotInCenter(centerIdToAvoid){
        return axios.get('/persons/not-in-center/'+centerIdToAvoid);
    },
    savePersonsFromOtherCenter(persons){
        let params = {persons: persons};
        return axios.post('/persons/from-other-center', params);
    },
    getPersonsById(personIds, schemaName) {
        let config = {
            'params': {
                'ids': personIds,
                'schemaName': ((schemaName != null) ? schemaName : null)
            }
        };

        return axios.get('/persons-by-id', config);
    },
    getPreregisteredChildrens(preregisterId, yearCode){
        return axios.get('/persons/preregistredChildsIds/'+preregisterId + '?year=' + yearCode);
    }
}
