<template>
    <ul class="sidebarnavClass">
        <li v-for="(item, i) in menuItems"
            class="vertical-center-inside"
            :class="[ menuElement(item.titleGroup), menuId(item), { 'lastElementMenuGroup': isLastElementMenuGroup(i), 'menuHasChildren flex-direction-column': hasChildren(item)} ] "
            :key="item.id"
            @click.prevent="toggleSubmenu(item, true)"
            @mouseenter="toggleSubmenu(item, true)"
            @mouseleave="toggleSubmenu(item, false)">
            <span v-if="item.titleGroup" class="titelGroup">{{ itemName(item) }}</span>
            <span v-if="item.titleGroup" class="title-group-separator"></span>
            <a class="w-100 align-items-center d-inline-flex justify-content-between" v-else-if="hasChildren(item)" href="#" @click.prevent="toggle(item)" >
                <div>
                    <i v-if="item.css" :class="'icon-fix-menu '+item.css"></i>
                    <span name="min" class="min icon-arrow-triangle-right"></span>
                    <span :class="['span-text', displayMenu(item) ]">{{ itemName(item) }}</span>
                </div>
                <i :class="[getMenuClass(item), 'pr-2']"></i>
            </a>
            <router-link v-else-if="!hasChildren(item)" :to="item.path ? {path:item.path} : {name:item.route}" @click.native="toggle(item)" class="w-100 align-items-center d-inline-flex justify-content-between">
                <div class="vertical-center-inside">
                    <span v-if="item.css" :class="'icon-fix-menu '+item.css"></span>
                    <span class="span-text" :class="{'child-class': !item.css}">{{ itemName(item) }}</span>
                </div>
                <i v-if="smallScreen" class="icon icon-arrow-carrot-right pr-2"></i>
            </router-link>
            <ul v-if="!item.titleGroup" :class="['menu-dropdown', {'hasChildren': hasChildren(item)} ]" v-show="showSubmenu(item)">
                <li :class="['subMenuTitle', displayMenu(item) ]" class="vertical-center-inside">
                    <router-link :to="{name:item.route}" @click.native="toggle(item)" class="align-items-center d-inline-flex justify-content-between">
                        <span :class="['span-text', displayMenu(item) ]">{{ itemName(item) }}</span>
                        <i v-if="smallScreen" class="icon icon-arrow-carrot-right pr-2"></i>
                    </router-link>
                </li>
                <li v-for="item_child in item.children" :key="item_child.id" :class="menuId(item_child)" class="vertical-center-inside w-100 li-item">
                    <router-link class="w-100 align-items-center d-inline-flex justify-content-between" :to="{name:item_child.route}" @click.native="toggle(item_child)">
                        <span class="span-text">{{ $tc(item_child.name, 2) }}</span>
                        <i v-if="smallScreen" class="icon icon-arrow-carrot-right pr-2"></i>
                    </router-link>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
    import Vue from 'vue';
    import { mapMutations, mapState } from 'vuex';
    import $ from 'jquery';

    export default {
        name: "LeftMenuGroup",
        props: {
            menuItems: {
                required: true
            }
        },
        data() {
            return {
            }
        },
        computed: {
            expandedMenuId: {
                get() {
                    return this.$store.getters.getExpandedMenuId;
                },
                set(value) {
                    this.$store.commit("setExpandedMenuId", value);
                }
            },
            ...mapState({
                deviceSize: state => state.deviceSize,
            }),
            smallScreen(){
                return this.deviceSize == "xs" || this.deviceSize == "sm";
            },
        },
        methods: {
            closeMenu(){
                let windowWidth = $(window).width();
                if(windowWidth <= 767){
                    if (!document.body.classList.contains( 'mini-sidebar' )){
                            document.body.classList.add( 'mini-sidebar' );
                    }
                }
            },
            isLastElementMenuGroup: function (i) {
                if (this.menuItems[i + 1] != undefined && this.menuItems[i].menuGroup != this.menuItems[i + 1].menuGroup) {
                    return true;
                }
                return false;
            },
            isFirstElementMenuGroup: function (i) {
                if ( this.menuItems[i].isConfig && ( this.menuItems[i - 1] == undefined || (this.menuItems[i - 1] != undefined && this.menuItems[i].menuGroup != this.menuItems[i - 1].menuGroup) ) ) {
                    return true;
                }
                return false;
            },
            menuElement(titel) {
                if (titel) {
                    return 'li-titel';
                } else {
                    return 'li-item';
                }
            },
            menuId(item) {
                let x = '';
                let id = item.id;
                let a = this.expandedMenuId;
                if (a == id) { // Per marcar el punt de menú pare
                    x = ' expanded ';
                }
                else if (this.hasChildrenExpanded(item)) {
                    x = ' expanded ';
                }
                return ' menu' + id + x;
            },
            hasChildren(items) {
                if (items.children && items.children.length > 0) {
                    return true;
                }
                return false;
            },
            isChild(child, father){
                return father.children.find(x => x.id === child);
            },
            hasChildrenExpanded(item) {
                let self = this;
                let result = false;

                if (Array.isArray(item.children)) {
                    item.children.some(function (children) {
                        if (children.id == self.expandedMenuId) {
                            result = true;
                            return true;
                        }
                    });
                }

                return result;
            },
            displayMenu(menu) {
                let m = '';
                if (this.expandedMenuId === menu.id) {
                    //m = m + " displayMenu"
                }
                return m;
            },
            getMenuClass(menu) {
                let c = ['icon'];
                if (this.expandedMenuId === menu.id || this.hasChildrenExpanded(menu)) {
                    c.push("icon-arrow-triangle-down")
                } else {
                    c.push("icon-arrow-triangle-right")
                }
                return c.join(" ")
            },
            toggle(item) {
                let menuReload = this.$store.getters.getMenuReload + 1;

                // console.log("leftmenugroup clicat - route.name: ",this.$route.name," - item.name: ",item.name);
                if (item.route != "") {
                    this.$store.commit("setMenuReload", menuReload);
                }
                if (this.expandedMenuId === item.id && this.hasChildren(item) || this.hasChildrenExpanded(item)) {
                    this.setExpandedMenuId(null);
                } else {
                    this.setExpandedMenuId(item.id);
                }
            },
            showSubmenu(item) {
                if (this.expandedMenuId === item.id || this.hasChildrenExpanded(item)) {
                    return true;
                } else {
                    return false;
                }
            },
            toggleSubmenu(item, visible) {
                let parent = $(".menu" + item.id)[0];
                let $submenu = $(".menu" + item.id + " > ul.menu-dropdown");

                if ($("body").hasClass("mini-sidebar")) {
                    if (visible) {
                        $submenu.css("height","36px !important");
                        $submenu.css("display", "block").css("position", "fixed").css("top", parent.getBoundingClientRect().top + "px").css("left", "70px");
                    } else {
                        $submenu.css("display", "none").css("position", "static");
                    }
                }
            },
            itemName(item){
                let name = '';
                if(item.route === 'persons'){
                    name =  this.$tc('user', 2);
                }
                else{
                    name = ([ 'centers' ].includes(item.name) ? this.$tc(item.name) : this.$tc(item.name, 2));
                }
                return name;
            },
            ...mapMutations(['setExpandedMenuId'])
            // toggle(id) {
            //   console.log("Toggling " + id);
            //   $('#child_' + id).slideToggle();
            //   $('#menu_' + id + ' i').toggleClass("icon-arrow-triangle-right");
            //   $('#menu_' + id + ' i').toggleClass("icon-arrow-triangle-down");
            // },
        },
        beforeMount() {
            this.$store.commit("setMenuReload", 0);
        }
    }
</script>

<style scoped lang="scss">
  @import "../../assets/css/variables";

    .sidebar-nav {
        ul {
            padding: 0;
            li {
                list-style: none;
                a {
                    display: block;
                    font-size: 14px;
                    white-space: nowrap;
                    height: 36px;
                    position: relative;
                    &.active {
                        color: $white !important;
                        font-weight: 500;
                        opacity: 1;
                        display: block;
                        height: 36px;
                        line-height: 36px;
                    }
                }
                ul ul { padding-left: 15px; }
                &.nav-label {
                    font-size: 12px;
                    margin-bottom: 0;
                    padding: 14px 14px 14px 20px;
                    color: #607d8b;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                &.nav-devider {
                    height: 1px;
                    background: rgba(120, 130, 140, 0.13);
                    display: block;
                }
            }
        }
        > ul > li {
            margin-bottom: 5px;
            > a {
                border-left: 3px solid transparent;
                .label {
                    position: absolute;
                    right: 35px;
                    top: 8px;
                }
                &.active {
                    font-weight: 400;
                    color: $leftMenuLinkHover;
                }
            }
            &.active > a {
                color: $leftMenuLinkHover;
                font-weight: 500;
                border-left: 3px solid #fff;
                i { color: $leftMenuLinkHover; }
            }
        }
    }

    .sidebar-nav > ul > li > a i, .dropdown-user > li > a i {
        width: 27px;
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
    }

    li {
        height: 36px;
    }

    .fix-header #menuConfig .sidebarnavClass .li-item .expanded {
        background-color: $brand2;
    }

    .child-class {
        padding-left: 1.5rem;
    }

    .title-group-separator {
        display: none;
    }

    .mini-sidebar {
        .li-titel { height: auto; }

        .titelGroup { display: none; }

        .title-group-separator {
            display: block;
            width: 14px;
            background-color: white;
            overflow: hidden;
            height: 1px;
            margin: 0.6rem auto;
            opacity: 0.24;
        }

        #menuConfig {
            .title-group-separator {
                background-color: #0c1921 !important;
            }
        }

        .sidebar-nav .sidebarnavClass > .li-item:hover > a .span-text {
            padding-left: 1.8rem !important;
        }

        .menu-dropdown.hasChildren .subMenuTitle .span-text {
            opacity: 0.5;
            margin-left: 0rem;
            font-size: 13px;
        }
    }
    @media (max-width: 991px) {
        .icon-arrow-triangle-down {
            margin-top: 0px;
        }
    }
</style>
