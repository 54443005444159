<template>
    <div class="loading">
        <label v-if="showLabelDeff" v-bind:id="'label-' + name" v-bind:for="name" :class="classObject">
            <template v-if="requiredField && viewEdit">* </template><span v-html="labelText"></span>
            <template v-if="extraInfo">
                <div class="inline-block" v-b-tooltip.hover.window :title="extraInfo">
                    <div class="wrapper-icon" v-if="extraInfoIcon !== ''">
                        <div :class="['icon', (extraInfoIcon).trim(), 'tooltip-icon', 'cursor-pointer']"></div>
                    </div>
                </div>
            </template>
            <counter-input
                v-if="counter && searchingInput != ''"
                :min="counterMinValue"
                :max="counterMaxValue"
                :length="searchingInput.length"
                class="float-right">
            </counter-input>
        </label>
        <div class="row">
            <i v-if="showSearchIcon" class="icon icon-search2 v-select-icon-search pr-2"></i>
            <div v-b-tooltip.window="tooltip" v-if="!multiple && !createOption" class="col no-padding">
                <v-select v-if="!multiple"
                          :class="css_class"
                          :options="data"
                          :name="name"
                          :placeholder="selectPlaceHolder"
                          :taggable ="isTaggable"
                          v-model="selected"
                          :label="label"
                          @search="searching"
                          @search:focus="focused"
                          @search:blur="unfocused"
                          @input="onChange"
                          @close="selectClosed"
                          @open="dropdownOpened"
                          :id="id"
                          :key="id"
                          :ref="selComponent"
                          :clearable="allowVoid"
                          :closeOnSelect="closeOnSelect"
                          :filterBy="filterBy"
                          v-validate="validate"
                          :searchable="searchable"
                          :components="{ Deselect, OpenIndicator }"
                          :tabindex="tabIndexParam"
                          :selectable="selectable"
                          :select-on-key-codes="selectOnKeyCodes"
                          :clearSearchOnSelect="clearSearchOnSelect"
                          @keydown.native="keyPressed"
                          @customKeyPress="keyPressed"
                          :append-to-body="true"
                          :data-vv-as="labelText"
                          :select-on-tab="selectOnKeyCodes.includes(9)"
                          :map-keydown="handlers"
                          :calculate-position="calculatePosition">
                    <template slot="no-options">
                        <template v-if="loading">
                            <spinner id="spinner1" class="inline-block" :status="true" :color="getThemeColor()"></spinner>
                            <div class="text-spiner inline-block">{{ $t('loading') }}</div>
                        </template>
                        <span class="no-options" v-else>{{ $t(noOptionsText) }}</span>
                    </template>
                    <div slot="selected-option" slot-scope="option" :class="['vertical-center-inside', {'text-center': textCenter}]">
                        <i v-if="option.icon" :class="option.icon"></i>
                        <user-image v-if="showUserImage" :userId="option.id" :name="option.name"  :surname="option.surname1"  extraClass="verysmall float-left"></user-image>
                        <span v-html="getOptionLabel(option)"></span>
                    </div>
                    <div slot="option" slot-scope="option":class="['vertical-center-inside', {'text-center': textCenter}]">
                        <i v-if="option.icon" :class="[option.icon, 'pr-2']"></i>
                        <user-image v-if="showUserImage" :userId="option.id" :name="option.name"  :surname="option.surname1"  extraClass="verysmall float-left"></user-image>
                        <span v-if="option.event" class="select-text-label" v-html="getOptionLabel(option)"></span>
                        <span v-else class="select-text-label" v-html="getOptionLabel(option)"></span>
                    </div>
                </v-select>
            </div>
            <div v-b-tooltip.window="tooltip" v-if="multiple && !createOption" class="col no-padding">
                <v-select v-if="multiple"
                          :class="css_class"
                          :options="data"
                          :name="name"
                          :placeholder="selectPlaceHolder"
                          v-model="selected"
                          :label="label"
                          @search:focus="focused"
                          @search:blur="unfocused"
                          @search="searching"
                          @input="onChange"
                          @open="dropdownOpened"
                          multiple
                          :taggable ="isTaggable"
                          :id="id"
                          :ref="selComponent"
                          :clearable="allowVoid"
                          :filterBy="filterBy"
                          v-validate="validate"
                          :components="{ Deselect, OpenIndicator }"
                          :tabindex="tabIndexParam"
                          :clearSearchOnSelect="clearSearchOnSelect"
                          :selectable="selectable"
                          :select-on-key-codes="[9]"
                          @keydown.native="keyPressed"
                          @customPress="keyPressed"
                          :data-vv-as="labelText"
                          :calculate-position="calculatePosition"
                          :append-to-body="true"
                          :closeOnSelect="closeOnSelect"
                          v-b-tooltip.window="tooltip">
                    <template slot="spinner" slot-scope="{ loading }">
                        <spinner v-if="loading" id="spinner2" class="inline-block" :status="true" :color="getThemeColor()">{{ $t('loading') }}</spinner>
                        <!-- <div v-if="loading" class="text-spiner inline-block">{{ $t('loading') }}</div> //-->
                    </template>
                    <template v-if="!loading" slot="no-options">
                        <span class="no-options">{{ $t(noOptionsText) }}</span>
                    </template>
                    <div slot="selected-option" slot-scope="option" class="vertical-center-inside">
                        <i v-if="option.icon" :class="option.icon"></i>
                        <user-image v-if="showUserImage" :userId="option.id" :name="option.name"  :surname="option.surname1"  extraClass="verysmall"></user-image>
                        {{ getOptionLabel(option) }} <!-- <i class="icon icon-close"></i> //-->
                    </div>
                    <div slot="option" slot-scope="option" class="vertical-center-inside">
                        <i v-if="option.icon" :class="[option.icon, 'pr-2']"></i>
                        <user-image v-if="showUserImage" :userId="option.id" :name="option.name"  :surname="option.surname1"  extraClass="verysmall"></user-image>
                        <span v-if="option.event" class="select-text-label" v-html="getOptionLabel(option)"></span>
                        <span v-else class="select-text-label" v-html="getOptionLabel(option)"></span>
                    </div>
                </v-select>
            </div>
            <div v-show="createOption" class="col no-padding">
                <input-ie v-model="newOption"
                          type="text"
                          :id="'newCreateOption'+selComponent"
                          name="newCreateOption"
                          :ref="'newCreateOption'+selComponent"
                          v-validate="validateInput"
                          :viewEdit="createOption"
                          :showLabel="false"
                          :data-vv-as="labelText"
                          @keyup.enter="saveCreate(actionCreateOption)"
                          :counter="counter"
                          :counterMinValue="counterMinValue"
                          :counterMaxValue="counterMaxValue"
                          :showErrorMsg="false">
                </input-ie>
            </div>
            <div v-if="viewEdit" class="d-inline-flex">
                <template v-for="(action, index) in actions">
                    <div v-if="(action.type === 'create' && !createOption && searchingInput === '') || action.type === 'other' || (createOption || searchingInput !== '')"
                        class="d-inline-flex align-self-center ml-2" :key="index">
                        <a href="#" @click="activeCreate" v-if="action.type === 'create' && !createOption && searchingInput === ''" class="wrapper-icon">
                            <i class="icon icon-plus-alt2" v-b-tooltip.window.hover="action.label ? $tc(action.label) : $tc('action.create')"></i>
                        </a>
                        <a href="#" v-else-if="action.type === 'other'" @click="eventAction(action.event)" v-b-tooltip.window.hover="action.label" class="wrapper-icon">
                            <i :class="['icon', 'icon-' + action.icon]"></i>
                        </a>
                        <a href="#" @click="saveCreate(action)" v-if="createOption || searchingInput !== ''" class="wrapper-icon">
                            <i v-if="action.type === 'create'" class="icon icon-floppy" v-b-tooltip.window.hover="$tc('action.save')"></i>
                        </a>
                        <a href="#" @click="cancelCreate" v-if="createOption || searchingInput !== ''" class="wrapper-icon">
                            <i v-if="action.type === 'create'" class="icon icon-close-alt2" v-b-tooltip.window.hover="$tc('action.cancel')"></i>
                        </a>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="!neverShowErrors" class="error_msg" :id="'error_' + (id ? id : (new Date()).getTime())">
            {{ errorMessage }}&nbsp;
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import $ from 'jquery';
import UserImage from '@/components/UserImage';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import { createPopper } from '@popperjs/core'
import StringFunctions from '@/utils/stringFunctions';
import InputIe from "@/components/InputIe";
import CounterInput from '../CounterInput.vue';

export default {
    name: "select-ie",
    model: {
        prop: 'selectedDefault',
        event: 'selected',
    },
    components: {
        vSelect,
        UserImage,
        Spinner,
        InputIe,
        CounterInput
    },
    inject: ['$validator'],
    props: {
        name: { type: String, required: false },
        placeholder: { type: String, required: false, default: function(){return this.$i18n.t('select')} },
        options: { type: Array, required: true, default: () => [] },
        id: { type: String },
        value: { type: String },
        icon: { type: String },
        label: { type: String, default: "label" },
        selectedDefault: { required: false, default: function(){return this.value} },
        viewEdit: { type: Boolean },
        css: { type: String },
        showLabel: { type: Boolean, required: false, default: true },
        multiple: { type: Boolean, required: false, default: false },
        errorMsg: { type: String, required: false },
        isTaggable: { type: Boolean, default: false },
        showUserImage: { type: Boolean, default: false },
        allowVoid: { type: Boolean, required: false, default: true },
        neverShowErrors: { type:Boolean, required: false , default: false },
        selectedProp: {type: String, required: false, default: null},
        requiredField:{type: Boolean, required:false, default: false},
        showSelector: {type: Boolean, required: false, default: true},
        searchable: {type: Boolean, required: false, default: true},
        actions: {type: Array, required: false, default: function() { return []; } },
        labelText: {type: String, required: false, default: ""}, //{position: 'first', label: 'Create something', event: 'eventCreate'}
        tabIndexParam: {type: Number, required: false, default: 0},
        extraInfo: {type: String, required: false},
        extraInfoIcon: {type: String, required: false},
        selectable: { type: Function, required: false, default: option => true },
        withPopper: { type: Boolean, required: false, default: false },
        showSearchIcon: { type: Boolean, required: false, default: false },
        tooltip: {type: String, required: false, default: ''},
        handleAutomaticLoading: { type: Boolean, required: false, default: true },
        noOptionsText: {type: String, required: false, default: "noOptions"},
        validateSelect: {required: false, default: ""},
        validateInput: {required: false, default: ""},
        counter: {type: Boolean, required: false, default: false},
        counterMinValue: {type: Number, required: false, default: 0},
        counterMaxValue: {type: Number, required: false, default: 0},
        propErrorStyle: {type: Boolean, required: false, default: false},
        selectIfOnlyOneOption: {type: Boolean, required: false, default: false}, //Por defecto a false, ya que hay muchos casos donde no se quiere que se seleccione automáticamente si solo hay una opción
        selectOnKeyCodes: {type: Array, required: false, default: function() { return [9]; } },
        clearSearchOnSelect: {type: Boolean, required: false, default: true},
        textCenter: {type: Boolean, required: false, default: false},
        closeOnSelectDefault: {type: Boolean, required: false, default: true},
        iconClose: {type: String, required: false, default: 'icon-close'},
        dropdownClass: {type: String, required: false, default: ''},
    },
    data() {
        return {
            selected: this.selectedDefault,
            errorClass: 'form-control',
            errorDisplay: this.errorMsg,
            errorVisibility: 'errorDiplay',
            //loading: (!(this.options && this.options.length > 0)),
            loading: false,
            data: this.options,
            selectPlaceHolder: this.placeholder,
            closeOnSelect: this.closeOnSelectDefault,
            Deselect: {
                render: createElement => this.viewEdit ? createElement('i', { class: ['icon', this.iconClose, 'deselector'] }) : '',
            },
            OpenIndicator: {
                render: createElement => createElement('div', { class: { 'selector-ppk': true } }),
            },
            highlightSelector: false,
            focusedOnSearch: false,
            searchingInput: '',
            createOption: false,
            newOption: '',
            errorStyle: this.propErrorStyle,
            popper: null,
        }
    },
    watch: {
        selectedDefault: {
            handler(newValue){
                //Comento estos cambios del Andrés porque deja de funcionar el SelectIe en muchos casos
                //(por ejemplo al seleccionar franja horaria al crear un evento en el horario)
                // if(!newValue?.value && !this.multiple && !this.allowVoid){//!newValue[this.label]
                //     this.setSelectAsDefault();
                // }
                // else{
                //     this.selected = newValue;
                // }
                if((newValue === null || (newValue && newValue.value === null)) && !this.multiple){
                    this.setSelectAsDefault();
                }
                else{
                    this.selected = newValue;
                }
            }
        },
        selected: {
            handler(newValue) {
                if (this.selectedProp!=undefined && this.selectedProp!= null && typeof newValue === 'object' && newValue) {
                    this.selected = newValue[this.selectedProp];
                    this.$refs[this.selComponent].mutableValue = newValue[this.selectedProp];
                } else {
                    this.selected = newValue;
                }
                if(newValue && !this.errorDisplay){
                    this.stopHighlight();
                }
            },
        },
        options: {
            handler(newValue) {
                this.data = newValue;
                this.data = this.setActions(this.lodash.cloneDeep(this.data));
                if(this.handleAutomaticLoading){
                    this.loading = false;
                    this.setLoading(false);
                }
                this.selected = this.selectedDefault;
            },
            deep: true,
        },
        actions:{
            handler(newValue){
                this.data = this.setActions(this.lodash.cloneDeep(this.options));
            },
            deep: true
        },
        viewEdit(newValue) {
            if( ((this.viewEdit && !this.multiple) || (!this.viewEdit)) && ((!this.selected || this.selected === null) || (this.selected && this.selected.value === null))){
                this.setSelectAsDefault();
            }
            if(newValue){
                this.selectPlaceHolder = this.placeholder;
            }
        },
        errorMsg(){
            this.errorDisplay = this.lodash.cloneDeep(this.errorMsg);
        },
        loading(newValue){
            if(newValue){
                this.selectPlaceHolder = this.$tc('loading');
            }
            else{
                this.selectPlaceHolder = this.placeholder;
            }
        },
        placeholder:{
            handler(newValue){
                this.selectPlaceHolder = newValue;
            }
        },
        errorDisplay: {
            handler(newValue){
                if(newValue && newValue.length > 0){
                    this.highlightInRed();
                }
                else{
                    this.stopHighlight();
                }
            }
        },
        searchInput: {
            handler(newValue){
                if(!newValue || newValue && newValue.length === 0){
                    this.data = this.setActions(this.options);
                }
            },
            deep: true
        },
        searchingInput: {
            handler(newValue){
                let self = this;
                if(newValue != ""){
                    this.$validator.validate().then(valid => {
                        if(!valid || (!self.lodash.isNil(self.errorMessage) && self.errorMessage != "")){
                            this.addError();
                        } else {
                            this.clearErrors();
                        }
                    });
                } else {
                    this.clearErrors();
                }
            }, deep: true
        },
        propErrorStyle: {
            handler(newValue){
                this.errorStyle = newValue;
            }
        },
        viewEdit:{
            handler(newValue){
                if(!newValue){
                    //Para evitar que siga saliendo el cursor dentro del SelectIe cuando se deja de editar
                    this.$refs[this.selComponent].$refs.search.blur();
                }
            }
        }
    },
    computed: {
        htmlLabelValue(){
            let htmlLabelValue = this.requiredField && this.viewEdit ? '* ': '';
            htmlLabelValue += this.labelText;
            return htmlLabelValue;
        },
        disabled: function () {
            return !this.viewEdit;
        },
        classObject: function () {

        },
        css_class: function () {
            let classes = ["form-control", {
                "read-only disable-click": !this.viewEdit,
                "multiple": this.multiple,
                "enabled": this.viewEdit,
                "hide-selector": !this.showSelector,
                "border-bottom-red": this.highlightSelector,
                "error-style-input": this.errorStyle,
            }];
            if(this.css){
                classes[1][this.css] = this.css;
            }
            return classes;
        },
        showLabelDeff: function () {
            let show = true;

            if (!this.showLabel) {
                show = false;
            }
            return show;
        },
        errorMessage: function () {
            if(this.errorDisplay && this.viewEdit){
                return this.errorDisplay;
            }
            else if (this.$validator._base.errors.has(this.name) && this.viewEdit) {
                return this.$validator._base.errors.first(this.name);
            }
            else{
                return '';
            }
        },
        validate(){
            let validate = "";
            if(Object.keys(this.validateSelect).length > 0 || this.validateSelect != ""){
                validate = this.validateSelect;
                if(this.requiredField){
                    validate.required = true;
                }
                return validate;
            }
            if(this.requiredField){
                validate = 'required';
            }
            return validate;
        },
        selComponent(){
            let r = (Math.random() + 1).toString(36).substring(7);
            return r;
        },
        actionCreateOption(){
            return this.actions.find(a => a.type == "create");
        },
    },
    methods: {
        dropdownOpened(){
            //Cuando se abre el dropdown, le añadimos la clase que se pasa en la prop
            let self = this;
            this.$nextTick(() => {
                if(self.dropdownClass && self.$refs[self.selComponent] && self.$refs[self.selComponent].$el){
                    if(document.querySelector('.vs__dropdown-menu')){
                        document.querySelector('.vs__dropdown-menu').classList.add(self.dropdownClass);
                    }
                }
            });
        },
        selectClosed(){
            this.$emit("selectClosed", this.selected);
        },
        handlers: (map, vm) => ({
            ...map,
            //Sobreescribimos el enter y el tab para que emitan un customKeyPress y saber que se ha pulsado
            //Sin esto, no se puede saber que se ha pulsado el tab o el enter ya que el v-select no lo emite
            9: (e) => {
                e.preventDefault()
                if (e.key === 'Tab'){
                    vm.onTab();
                    vm.$nextTick(() => {
                        vm.$emit('customKeyPress', e);
                    });
                }
            },
            13: e => {
                e.preventDefault()
                if(e.key === 'Enter'){
                    vm.typeAheadSelect();
                    vm.$nextTick(() => {
                        vm.$emit('customKeyPress', e);
                    });
                }
            }

        }),
        calculatePosition(dropdownList, component, { width }) {
            dropdownList.style.width = width;
            dropdownList.style.zIndex = 999999;

            this.popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'computeStyles',
                        options: {
                            adaptive: false,
                        },
                    },
                    {
                        name: 'toggleClass',
                        enabled: true,
                        phase: 'write',
                        fn({ state }) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                        },
                    },
                ],
            });

            return () => this.popper.destroy();
        },
        focused: function () {
            this.focusedOnSearch = true;
            this.$emit('focused', this.selected);
        },
        unfocused: function () {
            this.focusedOnSearch = false;
            // console.log("unfocused", this.selected);
            this.$emit('unfocused', this.selected);
        },
        onChange: function (e) {
            let self = this;

            if(Array.isArray(e)){
                e.forEach(function(element){
                    if(element && element.hasOwnProperty('event')){
                        // Is an action.
                        self.loading = true;
                        self.$refs[self.selComponent].toggleLoading(self.loading);
                        if(!element.closeOnSelect){
                            self.$refs[self.selComponent].onAfterSelect(true);
                        }

                        self.$refs[self.selComponent].deselect(element);
                        if(!self.lodash.isNil(self.searchingInput)){
                            self.$emit(element.event, self.searchingInput);
                        }
                    }
                });
            }
            else{
                if(e && e.hasOwnProperty('event')) {
                    // Is an action.
                    self.loading = true;
                    self.$refs[self.selComponent].toggleLoading(self.loading);

                    if(!e.closeOnSelect){
                        self.$refs[self.selComponent].onAfterSelect(true);
                    }

                    self.$refs[self.selComponent].deselect(e);
                    if(!self.lodash.isNil(self.searchingInput)){
                        self.$emit(e.event, self.searchingInput);
                    }
                }
                if(this.lodash.isNil(e)){
                    this.$emit("clear");
                }
            }
            if(this.selected && this.selected.value === null){
                this.$emit('selected', null);
            }
            else{
                this.$emit('selected', this.selected);
            }
            // this.$nextTick();
        },
        getValues: function () {
            return this.selected;
        },
        capitals: function (name, surname) {
            let letters;
            if (name != "" && name != 'undefined') {
                letters = name.charAt(0).toUpperCase();
            }
            if (surname != 'undefined' && surname != '') {
                letters += surname.charAt(0).toUpperCase();
            }
            return letters;
        },
        getOptionLabel(option) {
            if (option && this.label in option) {
                return option[this.label];
            } else if(option){
                return option.label;
            }
        },
        searching(input, loading) {
            //Comento el loading porque en la mayoría de sitios, la búsqueda la hacemos en el options y no en la BD.
            //En los sitios donde se haga búsqueda a la BD, se tendrá que poner el loading a true en el metodo que captura este emit.
            // loading(true);
            // this.loading = true;
            // this.$refs[this.selComponent].toggleLoading(this.loading);+
            let self = this;
            self.popper.forceUpdate();
            setTimeout(function() {
                self.searchingInput = input;
                self.data = self.setActions(self.options);
                self.$emit('searching', input, loading);
            }, 50); //Sin este delay, desaparece el botón de guardar antes de darle click
        },
        setValue(value){
            this.selected = value;
        },
        setLoading(loading){
            this.loading = loading;
            if(this.$refs[this.selComponent]){
                this.$refs[this.selComponent].toggleLoading(this.loading);
            }
        },
        removeSelection(){
            // this.selected = { label: this.$t("select"), value: null, selectable: true, };
            this.selected = null;
        },
        setSelectAsDefault(){
            this.selected = null;
            if(this.data.length === 1 && this.selectIfOnlyOneOption && !this.searchingInput){
                this.selected = this.data[0];
                this.$emit('selected', this.selected);
            }
        },
        setActions(data){
            if(this.allowVoid){
                if(!this.selectedDefault && this.viewEdit && !this.multiple){
                    this.setSelectAsDefault();
                }
            }
            let self = this;
            this.actions.forEach(function (action){
                if(action.type && action.type === 'create'){ //Si es un acción de crear
                    if(self.searchingInput !== ''){ //Si estamos buscando algo
                        let existsIndex = data.findIndex(x => x.value === action.value);
                        if(existsIndex < 0){ //Si la acción no existe
                            if (action.position === 'FIRST'){
                                if(!self.similarOptionInList(self.searchingInput)){ //Si no hay opciones similares, le ponemos otro texto
                                    action[self.label] = self.$tc('optionDoesntExistCreate');
                                }
                                else{
                                }
                                data.unshift(action)
                            }
                            else if (action.position === 'LAST'){
                                data.push(action);
                            }
                        }
                        else{ //Si la acción existe
                            if(!self.similarOptionInList(self.searchingInput)){ //Si no hay opciones similares, le ponemos otro texto
                                data[existsIndex][self.label] = self.$tc('optionDoesntExistCreate', 1, {option: self.searchingInput});
                            }
                            else{
                                data[existsIndex][self.label] = "<span class=\"link-look\">"+self.$tc('action.create')+ " '"+self.searchingInput+"' </span>";
                            }
                        }
                    }
                    else{ //Si no estamos buscando nada, quitar el crear
                        let existsIndex = data.findIndex(x => x.value === action.value);
                        if(existsIndex >= 0){
                            data.splice(existsIndex, 1);
                        }
                    }
                }
                else{ //Si no es una acción de crear, la añadimos si no existe
                    let existsIndex = data.findIndex(x => x.value === action.value);
                    if (action.position === 'FIRST' && existsIndex < 0){
                        data.unshift(action)
                    }
                    else if (action.position === 'LAST' && existsIndex < 0){
                        data.push(action);
                    }
                }
                if(action.type && action.type === 'error'){
                    action[self.label] = action.name;
                    self.$validator.validate();
                    if(!self.lodash.isNil(self.searchingInput) && self.searchingInput != ""){
                        self.$validator.validate().then(valid => {
                            if(!valid){
                                let existError = data.find(a => a.value === action.value);
                                if(existError == undefined || (Array.isArray(existError) && existError.length < 0)){
                                    data.push(action)
                                }
                            } else {
                                let existsIndexErrorValid = data.findIndex(x => x.value === action.value);
                                if(existsIndexErrorValid >= 0){
                                    data.splice(existsIndexErrorValid, 1);
                                }
                            }
                        });
                    } else {
                        let existsIndexError = data.findIndex(x => x.value === action.value);
                        if(existsIndexError >= 0){
                            data.splice(existsIndexError, 1);
                        }
                    }
                }
            });
            return data;
        },
        eventAction(event){
            this.$emit(event);
        },
        filterBy(option, label, search) {
            let labelValue = "";
            if (option.event){
                if(option.type === 'create' && search.length > 0 && !this.exactOptionExists(search)){
                    return 1;
                }
                else if(!option.type || option.type !== 'create' || option.type == "error"){
                    return 1;
                }
                else{
                    return 0;
                }
            }
            else{
                labelValue = this.getOptionLabel(option);
                labelValue = StringFunctions.normalize(labelValue);
                let searchValue = StringFunctions.normalize(search);
                return labelValue.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || ("").toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
            }
        },
        highlightInRed(){
            this.highlightSelector = true;
        },
        stopHighlight(){
            this.highlightSelector = false;
        },
        forceDropdown(){
            this.$refs[this.selComponent].$refs.search.focus();
        },
        keyPressed(event){
            this.$emit("keyPressed",event);
            if(event.keyCode == 13 || event.keyCode == 9){
                let value = this.newOption ? this.newOption : this.searchingInput;
                let pos = this.options.findIndex(x => x[this.label] === value);
                if(pos>=0){
                    this.selected = this.options[pos];
                    this.$emit('selected', this.selected);
                }
            }
        },
        exactOptionExists(search){
            let exists = this.options.find(x => x[this.label] === search);
            return exists;
        },
        similarOptionInList(search){
            let found = false;
            let self = this;
            this.data.some(function(option){
                if(option[self.label] && option[self.label].includes(search) && !option.event){
                    found = true;
                    return true;
                }
            });
            return found;
        },
        activeCreate(){
            this.createOption = true;
            this.$nextTick(function(){
                this.$refs['newCreateOption'+this.selComponent].focus();
            });
        },
        saveCreate(action){
            let value = this.newOption ? this.newOption : this.searchingInput;
            this.$emit(action.event, value);
            this.$nextTick(() => {
                this.cancelCreate();
            })
        },
        cancelCreate(){
            this.createOption = false;
            this.newOption = "";
            this.searchingInput = "";
        },
        addError(){
            let self = this;
            let action = {label: "<span class='text-danger'>"+self.errorMessage+"</span>",
                value: self.errorMessage,
                type: "error",
                position: 'LAST',
                event: "error",
            };
            let existError = self.data.find(a => a.value === action.value);
            if((existError == undefined || (Array.isArray(existError) && existError.length < 0))
                && !self.lodash.isNil(self.errorMessage) && self.errorMessage != ""){
                self.clearErrors();
                self.data.push(action);
            }
        },
        clearErrors(){
            let self = this;
            let indexDataValid = self.data.findIndex(x => x.type === 'error');
            let indexActionValid  = self.actions.findIndex(x => x.type === 'error');
            if(indexDataValid >= 0){
                self.data.splice(indexDataValid, 1);
            }
            if(indexActionValid >= 0){
                self.actions.splice(indexActionValid, 1);
            }
        },
    },
    mounted() {
        //this.$refs[this.selComponent].loading = this.loading;
        this.data = this.setActions(this.lodash.cloneDeep(this.options));
        this.setLoading(this.loading);
        if(!this.multiple && (this.selectedDefault == null || typeof this.selectedDefault === 'object' && this.selectedDefault && this.label in this.selectedDefault && !this.selectedDefault[this.label])){
            this.setSelectAsDefault();
        }
        else{
            this.selected = this.selectedDefault;
        }
        if(this.errorDisplay){
            this.highlightInRed();
        }
        //Si no estamos en modo edición, no mostrar el placeholder "Seleccionar"
        if(!this.viewEdit && this.selectPlaceHolder === this.$i18n.t('select')){
            this.selectPlaceHolder = "";
        }
        this.$emit("mounted");
    },
}


</script>
<style lang="scss">
@import "../../assets/css/variables.scss";
@import url("../../assets/css/ieduca_font.css");
@import "../../../node_modules/vue-select/dist/vue-select.css";
@import "../../assets/css/preregistration_state";

.v-select {
    border-bottom: 1px solid $linetable;
    background-color: transparent;
    .vs__dropdown-toggle {
        border: 0px !important;
        cursor: pointer;
        .clear {
            font-size: 1.1rem;
            display: block;
            position: absolute;
            right: 1rem;
            top: 0.75rem;
        }
    }
    .vs__actions{
        &:after { display: none; }
        &:has(.icon-close-alt){
            .selector-ppk{
                width: 5px;
            }
            button.vs__clear{
                right: 0px;
            }
        }
    }
    .vs__dropdown-menu {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        display: block;
        z-index: 100000 !important;
        scrollbar-width: thin;
        > .highlight > a {
            background-color: transparent;
            color: $brand2;
        }
        .active > a { background-color: transparent; }
    }
    &.open .vs__actions { padding:0px !important; }
    input[type=search] {
        width: 0px !important;
        min-height: 1%;
        margin: 0 !important;
        padding: 0 !important;
    }
    .vs__dropdown-toggle, input[type=search], .vs__dropdown-toggle .clear {
        background-color: transparent !important;
    }
    .icon {
        padding-right: 10px;
        &::before{
            line-height: 0;
        }
    }
    .vs__selected {
        max-height: 1.8rem !important;
        border: none;
        margin: 0 !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:has(.text-center){
            justify-content: center;
        }
    }
    &:hover { border-bottom: 1px solid $brand1 !important; }
    &.multiple .vs__dropdown-toggle{
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        .vs__selected-options {
            white-space: nowrap;
        }
    }
    .selected-tag {
        border: none;
        background-color: $tagsColor;
        height: 2.5rem;
        margin-top: 0.75rem;
        padding: 0.5rem 1rem;
        border-radius: 3rem;
    }
}

.vs__dropdown-toggle {
    margin-bottom: -1px;
    padding: 0 !important;
    &::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: none;
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
    &:after, &::after { display: none; }
}

.hide-selector .vs__actions{ display: none; }

.dropdown {
    &.v-select.multiple{ padding: 0.7rem 0rem 0rem 0rem !important; }
    li {
        &:hover {
            background-color: $brand2_01;
            color: $brand2;
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

::-webkit-scrollbar-thumb {
    background: $veryLightGrey;
    border-radius: 3.5px;
    height: 10px;
    width: 8px;
    &:vertical { height: 2px !important; }
}
.vs__dropdown-menu ::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
}
::-webkit-scrollbar {
    width: 8px;
    border-radius: 3.5px;
    height: 8px;
}

.vs__open-indicator {
    position: absolute;
    top: 8px;
    right: 6px;
    display: inline-block;
    cursor: pointer;
    pointer-events: all;
    transition: all 150ms cubic-bezier(1.000, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
}
.read-only .vs__open-indicator {
    position: absolute;
    top: 14px;
    right: 10px;
    display: none;
    cursor: pointer;
    pointer-events: all;
    transition: all 150ms cubic-bezier(1.000, -0.115, 0.975, 0.855);
    transition-timing-function: cubic-bezier(1.000, -0.115, 0.975, 0.855);
}

.v-select, div.open{
    .vs__open-indicator:before{
        font-family: "ieduca" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 0.8;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e0b8";
        border: none;
        transform: none !important;
        color: $chosenLine;
        font-size: 18px !important;
    }
}
.form-control input::placeholder {
    color: $placeHolderTextColor;
    opacity: 1;
}

.selected-tag > .icon ~ input[type=search] {
    margin-left: 2rem !important;
}

button.vs__clear {
    position: absolute;
    top: 2px;
    right: 10px;
    &:hover{ color: red !important; }
    @media (min-width: 576px) and (max-width: 768px) {
        top: 3px;
    }
}

.dropdown-menu[role='listbox']{ z-index: 1; }

.user-image {
    background-size: contain;
    background-position: center center;
    border-color: #6B6B6B;
    + .select-text-label {
        display: block;
        line-height: 40px;
    }
}

.select-text-label{ white-space: pre; }

#centerSelected .vs__dropdown-toggle .vs__selected-options .vs__selected {
    border-radius: 1.5rem;
    .user-image{ margin-left: -4px; }
}

.vs__deselect{
    margin-left: 0.3rem;
}

.academic-year-component{ display: contents; }

.vs__selected-options{
    align-items: center;
    max-width: 100%;
    padding: 0 0 0 0 !important;
}
.enabled .vs__selected-options{
    //Este padding es para dejar espacio a la X y a la flechita para desplegar, solo se muestra en modo edición
    padding: 0 35px 0 0 !important;
}

.vs__search { border-bottom: none !important; }

.selector-ppk {
    width: 14px;
    height: 10px;
    right: 2px;
    &::before {
        position: relative;
        right: -2px !important;
        top: 10px !important;
        color: #999 !important;
        margin-top: 4px !important;
        border-color: #999 transparent transparent !important;
        border-style: solid !important;
        border-width: 5px 5px 0 !important;
        content: "" !important;
    }
}

.deselector {
    padding-right: 0 !important;
    margin-top: 3px;
    color: rgba(60,60,60,.5) !important;
    font-size: 1rem !important;
    text-shadow: none !important;
    &.icon-close-alt{
        color: rgba(60, 60, 60, 0.65) !important;
    }
}
.border-bottom-red { border-bottom: 1px solid red !important; }
.v-select-icon-search{
    color: #5a687275;
    display: flex;
    align-items: center;
}

.minWidth {
    min-width: 150px;
}

.highlighted .v-select{
    background-color: #ff9600 !important;
    border-radius: 20px;
    text-indent: 15px;
    width: calc(100% - 10px);
}

</style>
