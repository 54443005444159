<template>
    <span v-b-tooltip.hover="{ title: name + ' ' + surname, disabled: isTooltipDisabled }"
            :data-original-title="name + ' ' + surname"
            @mouseover="showEditButton ? editButtonVisible = true : editButtonVisible = false"
            @mouseleave="editButtonVisible = false"
            :class="{'user-image-component': true, 'user-image-wrapper': editButtonVisible}">
        <div :class="imgClass" :style="style+styleHW">
            <div class="justify-content-center vertical-center-inside w-100">
                <span class="user-image-component" v-show="!style || style=='background-image: url(\'pending\')'">{{ capitals }}</span>
            </div>
        </div>
        <div v-if="editButtonVisible" class="tool-box">
            <a href="#" @click.prevent="$emit('editImage')" class="tool-button shadow" v-b-tooltip.hover="$tc('editProfileImage')"><i class="icon icon-pencil-edit"></i></a>
        </div>
    </span>
</template>

<script>
    import $ from 'jquery';
    import { mapState } from 'vuex';

    export default {
        name: 'UserImage',
        props: {
            userId: { type: [ Number, String ], required: false },
            name: { type: String, required: false },
            surname: { type: String, required: false },
            extraClass: { required: false },
            useImage:{ type: Boolean, required: false, default: true },
            showEditButton: { type: Boolean, required: false, default: false },
            disableTooltip: { type: Boolean, required: false, default: false },
            heightStyle: {type: String, required: false},
            widthStyle: {type: String, required: false},
        },
        data() {
            return {
                image: "",
                editButtonVisible: false,
                isTooltipDisabled: this.disableTooltip,
            }
        },
        computed: {
            style() {
                let userImg = "";
                if (this.image && this.image!="notExist") {
                    userImg = this.image;
                } else if(this.useImage) {
                    userImg = this.$store.getters.userImage(this.userId); //Get or make a petition to get the user image
                }
                if (userImg !== "" && userImg !== undefined && userImg !== null && userImg!="notExist" && userImg !== "undefined") {
                    return "background-image:url('" + userImg + "');background-size:contain;"; //If the user has an image, return it
                }
                return "";
            },
            styleHW() {
                let style = "";
                if(this.heightStyle && this.heightStyle != ""){
                    style += "height:" + this.heightStyle + ";";
                }
                if(this.widthStyle && this.widthStyle != ""){
                    style += "width:" + this.widthStyle + ";";
                }
                return style;
            },
            imgClass() {
                let classes = ["user-image"];
                let color = "color" + (this.userId % 13);
                classes.push(color);
                if(!this.lodash.isNil(this.extraClass)){
                    if(Array.isArray(this.extraClass)){
                        classes = classes.concat(this.extraClass);
                    } else {
                        classes.push(this.extraClass);
                    }
                }
                return classes;
            },
            capitals() {
                let capitals = "";
                if (typeof this.name !== "undefined" && typeof this.name !== 'object') {
                    capitals += this.name.charAt(0).toUpperCase();
                }
                if (typeof this.surname !== "undefined") {
                    capitals += this.surname.charAt(0).toUpperCase();
                }
                return capitals;
            },
            ...mapState({
                imagesStore: state => state.users.images,
                canLoadImages: state => state.users.canLoadImages,
            }),
        },
        mounted() {
            this.getUserImage();
        },
        watch: {
            canLoadImages:{
                handler(newValue) {
                    if(newValue){
                        this.getUserImage();
                    }
                },
            },
            userId(userId) {
                this.userId = userId;
            },
            name(name) {
                this.name = name;
            },
            surname(surname) {
                this.surname = surname;
            },
            imagesStore: {
                handler(newValue) {
                    // console.log(this.userId, "imagesstore ", newValue);
                    this.image = newValue[this.userId];
                },
                deep: true
            }
        },
        methods:{
            getUserImage(){
                if (this.userId != null) {
                    if (process.env.NODE_ENV !== 'development') {
                        this.$store.dispatch("getUserImage", {userId: this.userId});
                    }
                    this.$nextTick(() => {
                        this.image = this.$store.getters.userImage(this.userId)
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/css/colors.scss";
    .tooltip{
        pointer-events: none;
    }
    .user-image {
        background-color: $brand1;
        border: solid 1px $brand1_less;
        border-radius: 100%;
        color: #ffffff;
        font-size: 1rem;
        padding: 0.23rem 0rem;
        font-weight: regular;
        display: inline-flex;
        width: 2rem; /*38px aprox*/
        height: 2rem;
        background-size: 2.45px 2.45px;
        vertical-align: middle;
        text-transform: uppercase;
        &:hover{
            filter:contrast(115%) brightness(105%);
        }

        &.verysmall {
            margin: 0rem 0.3rem 0rem 0rem;
            width: 1.65rem;
            height: 1.65rem;
            background-size: 1.70rem 1.70rem;
            font-size: 0.8rem;
            line-height: 1.1rem;
        }

        &.small {
            width: 2rem; /* 75 */
            height: 2rem;
            background-size: 2.05rem 2.05rem;
            font-size: 1rem;
            line-height: 1.3rem;
        }

        &.medium {
            width: 4.6rem; /* 75 */
            height: 4.6rem;
            background-size: 4.65rem 4.65rem;
            font-size: 2rem;
            line-height: 4rem;
        }

        &.large {
            width: 7.5rem; /* 120 */
            height: 7.5rem;
            background-size: 7.52rem 7.52rem;
            font-size: 4rem;
            line-height: 6rem;
        }

        &.extralarge {
            width: 10rem;
            height: 10rem;
            background-size: 12.2rem 12.2rem; /*195px aprox*/
            font-size: 5rem;
            line-height: 8.8rem;
        }

        &.no-left-space {
            padding-left: 0 !important;
            margin-left: 0 !important;
        }
    }
    .user-image.noHover:hover {
        filter: none;
    }

    .tool-box {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 30px;
        color: red;

        .tool-button {
            display: block;
            width: 30px;
            height: 30px;
            background-color: #3ed396;
            border-radius: 50%;
            position: relative;

            .icon {
                color: #FFFFFF;
                font-size: 14px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -8px;
                margin-top: -10px;
            }
            &:hover {
                background-color: darken(#3ed396, 10);
            }
        }

        &:hover{
            color: blue;
        }
    }
    .user-image-wrapper{
        position: relative;
        display: block;
    }
    @media (max-width: 420px){
        .user-image {
            margin: 0;
            margin-right: 5px;
            margin-top: 5px !important;
        }
    }
</style>
