import i18n from './../translations';

export default  {
    firstToCapital(string){
        return string.charAt(0).toUpperCase()+string.slice(1);
    },
    getInicials(nom){
        if(nom) {
            let letters = nom.split(' ');
            let inicials = letters.filter(o => o.length>1).map(x => x.charAt(0).toUpperCase()).toString();
            return inicials.replaceAll(',','');
        }
        return '';
    },
    highlightText(str, q) {
        if(!q) return str;
        if (str == null || str == undefined) {
            return "";
        }

        let accentMap = {
            'á': 'a', 'à': 'a', 'ä': 'a', 'â': 'a', 'Á': 'A', 'À': 'A',
            'é': 'e', 'è': 'e', 'ë': 'e', 'ê': 'e', 'É': 'E', 'È': 'E',
            'í': 'i', 'ï': 'i', 'Í': 'I', 'Ï': 'I',
            'ó': 'o', 'ò': 'o', 'Ó': 'O', 'Ò': 'O',
            'ú': 'u', 'ü': 'u', 'Ú': 'U', 'Ü': 'U'
        };

        let accentFold = (s) => {
            let ret = '';

            if (!s) {
                return '';
            }
            for (var i=0; i<s.length; i++) {
                ret += accentMap[s.charAt(i)] || s.charAt(i);
            }

            return ret;
        };

        let strWithOutStrong = str.replace(/<strong>/g, '').replace(/<\/strong>/g, '');
        let strFolded = accentFold(str).toLowerCase().replace(/[\{\}]+/g, '').replace(/<strong>/g, '{').replace(/<\/strong>/g, '}');
        let qFolded = accentFold(q).toLowerCase().replace(/[\{\}]+/g, '');
        let re = new RegExp(qFolded, 'g');
        let hiliteHints = strFolded.replace(re, '{'+qFolded+'}');
        let spos = 0;
        let highlighted = '';
        let c = "";
        let h = "";
        let i = 0;

        for (i = 0; i < hiliteHints.length; i++) {
            c = strWithOutStrong.charAt(spos);
            h = hiliteHints.charAt(i);
            if (h === '{') {
                highlighted += '<strong>';
            } else if (h === '}') {
                highlighted += '</strong>';
            } else {
                spos += 1;
                highlighted += c;
            }
        }

        return highlighted;
    },
    highlightTextNoHmtl(text, txtToFind){
        let words = []
        let textParts = text.split(/(<[^>]+>)/g);
        let textModified = '';
        if (txtToFind != null) {
            textModified += textParts.map(function (part) {
                if (part.match(/<[^>]+>/)) {
                    return part; // It's an HTML tag, leave it as is
                } else {
                    words = part.split(" ")
                    let partModified = '';
                    for (let i = 0, len = words.length; i < len; i++) {
                        partModified = this.highlightText(words[i], txtToFind)+' ';
                    }
                    return partModified;
                }
            });
        }else{
            textModified = text;
        }
        return textModified;
    },
    randomString() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    truncateString(str, num, endChar = ".") {
        if (str == null) {
            return "";
        }

        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + endChar;
    },
    formatIPSOptionType(option){
        option = option.substring(1, option.length-1);
        let options = option.split(",");
        let result = "";
        options.forEach(function (e) {
            let translated = i18n.tc(e);
            if(translated !== e){
                result += ", "+translated;
            }
        });
        result = result.substring(2);
        if(result.length > 0){
            result = "("+result+")";
        }
        return result;
    },
    generateSlugFrom(text)  {
        let textValue = (typeof text == 'string' ? text : "");
        let fromList = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
        let toList   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

        if (textValue == "") {
            return "";
        }

        for (let i = 0, len = fromList.length ; i < len ; i++) {
            textValue = textValue.replace(new RegExp(fromList.charAt(i), 'g'), toList.charAt(i));
        }

        textValue = textValue.replace(/[^a-z0-9s-]/gi, "");
        textValue = textValue.replace(/\s/g, "-");
        textValue = textValue.replace(/\-\-+/g, "-");
        textValue = textValue.replace(/[\W]/g, "");

        return textValue;
    },
    generateCodeFrom(text, len) {
        let slugValue = this.generateSlugFrom(text);
        let lenValue = (typeof len == 'number' ? len : 0);

        if (slugValue == "") {
            return "";
        }

        slugValue = slugValue.toUpperCase();
        slugValue = slugValue.replace(/\-/g, "");

        if (lenValue > 0) {
            slugValue = slugValue.substring(0, lenValue);
        }

        return slugValue;
    },
    normalize(str) {
        // Elimina els accents d'un string i el converteix a minúscules.
        // ex) "El temps és plujós" => "el temps es plujos"
        if(typeof str !== 'string') return str;
        return str.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    tokenize(str) {
        // Separa els string en paraules i elimina accents i el converteix a minúscules.
        // ex) "El temps és plujós" => [ "el", "temps", "es", "plujos" ]
        let parts = str.trim().split(" ");

        return parts.map(item => this.normalize(item)).filter(element => element);
    },
    canBeCode(text){
        //Expressió regular per determinar si un string és vàlid o no per ser un codi
        //Carácters permesos: A-Z, a-z, 0-9, -, ., _
        let regex = /^[a-z0-9Çç\-._]+$/i;
        if(text.match(regex)){
            return true;
        }
        else{
            return false;
        }
    },
    truncateWords(text, length, endString = "...") {
        let textValue = "";
        let words = [];
        let stop = false;
        let finalLength = length - endString.length;

        if (text.length > finalLength) {
            words = text.split(" ");
            textValue = words[0];
            if (textValue.length > finalLength) {
                textValue = textValue.substring(0, finalLength);
            } else {
                for (let i = 1, len = words.length; i < len && !stop; i++) {
                    if ((textValue + " " + words[i]).length > finalLength) {
                        stop = true;
                    } else {
                        textValue += " " + words[i];
                    }
                }
            }
            textValue = textValue + endString;
        } else {
            textValue = text;
        }

        return textValue;
    },
}

